import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { PrescriptionService } from '../service/prescription.service';
import { Pharmacy } from '../shared/models/pharmacy.model';
declare var $: any;
@Component({
  selector: 'app-delivery-routing',
  templateUrl: './delivery-routing.component.html',
  styleUrls: ['./delivery-routing.component.css']
})
export class DeliveryRoutingComponent implements OnInit {
  //https://github.com/shivamdixit/maps/blob/master/shortest.html
  ///https://github.com/christianwbsn/a-star-shortest-path-with-google-maps-api/blob/master/frontend/js/map.js
  mylat = 0;
  mylon = 0;

  totalDistance;
  directionsDisplay;
  directionsService = new google.maps.DirectionsService();
  map;
  origin = "";
  pharmacieList: Pharmacy[] = [];
  constructor(private prescriptionService: PrescriptionService, private _snackBar: MatSnackBar,private router:Router) {
    //this.locations.push([null]);
    // this.getCurrentLocation();
    this.pharmacieList = localStorage.getItem("pharmacieList") ? (JSON.parse(localStorage.getItem("pharmacieList")) as [Pharmacy]) : null;

    this.pharmacieList.forEach(element => {
      var pharmacyAddress = element.name + " " +
        element.add1 + " " + (element.add2 ? (element.add2 + " ") : "") + "" + element.pin;
      element.name = pharmacyAddress;
    });
    this.loadData();
  }
  listAddress = [];
  ngOnInit(): void {

    this.initialize();
  }
  private getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.mylat = position.coords.latitude;
        this.mylon = position.coords.longitude;
        this.initialize();
        // this.prescriptionService.findAddressUsingLatLon({
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // }).then(x => {
        //   console.log(x);
        // }).catch(x => {
        //   this._snackBar.open(x, "OK", {
        //     duration: 4000,
        //   });
        // });
      }, err => {
        this._snackBar.open(err.message + ". Please allow us to get your location", "OK", {
          duration: 4000,
        });
      });
    }
  }
  originChange() {
    console.log(this.origin);
    $('.csLoader').css({ display: 'flex' });
    this.prescriptionService.getLatLonByAddress(this.origin).then(res => {
      $('.csLoader').css({ display: 'none' });
      this.mylat = res.lat();
      this.mylon = res.lng();
      this.initialize();
    }).catch(e => {
      $('.csLoader').css({ display: 'none' });
      this._snackBar.open(e, "Error", {
        duration: 4000,
      });
    });
  }
  originMarker = null;
  initialize() {                 //To initialize google maps
    this.directionsDisplay = new google.maps.DirectionsRenderer();
    var mapOptions = {
      center: new google.maps.LatLng(this.mylat, this.mylon),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: this.mapSetting as [google.maps.MapTypeStyle],
    };
    this.map = new google.maps.Map(document.getElementById("map"),
      mapOptions);
    var infowindow = new google.maps.InfoWindow({
      content: this.origin
    });
    this.originMarker = new google.maps.Marker({
      icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      draggable: true,
      position: new google.maps.LatLng(this.mylat, this.mylon),
      map: this.map
    });
    infowindow.open(this.map, this.originMarker);
    this.directionsDisplay.setMap(this.map);
    this.directionsDisplay.setPanel(document.getElementById("directionsPanel"));
    google.maps.event.addListener(this.originMarker, 'dragend', () => {
      this.getSelectedAddress(this.originMarker, infowindow);
    });
    this.getSelectedAddress(this.originMarker, infowindow);
  }

  calculateVehicleRoute() {

    this.calcDistance();
  }

  getSelectedAddress(marker: any, infowindow: any) {
    this.prescriptionService.findAddressUsingLatLon(marker.getPosition()).then(responses => {
      if (responses && responses.length > 0) {

        marker.formatted_address = responses[0].formatted_address;
      } else {
        marker.formatted_address = 'Cannot determine address at this location.';
      }
      infowindow.setContent(marker.formatted_address + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
      infowindow.open(this.map, marker);
    }).catch(x => {
      marker.formatted_address = 'Cannot determine address at this location.';
      infowindow.setContent(marker.formatted_address + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
      infowindow.open(this.map, marker);
      this._snackBar.open(x, "OK", {
        duration: 4000,
      });
    });
  }
  calcDistance() {
    console.log(this.listAddress);                                //To calculate distance
    var matrixService = new google.maps.DistanceMatrixService();
    matrixService.getDistanceMatrix({
      origins: [this.origin],
      destinations: this.listAddress,
      travelMode: google.maps.TravelMode.DRIVING,
      avoidHighways: false,
      avoidTolls: false
    }, (response, status) => {
      this.callback(response, status);
    });
  }
  callback(response, status) {
    if (status != google.maps.DistanceMatrixStatus.OK) {

      this._snackBar.open("Sorry, it was an error: " + status, "OK", {
        duration: 4000,
      });
    }

    else {
      var routes = response.rows[0];
      console.log(response);
      var sortable = [];
      for (var i = routes.elements.length - 1; i >= 0; i--) {
        var routeLength = routes.elements[i].distance.value;
        var addr = this.listAddress[i];
        sortable.push([addr, routeLength]);
      }

      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });

      var waypoints = new Array();

      for (var j = 0; j < sortable.length - 1; j++) {
        console.log(sortable[j][0]);
        waypoints.push({
          location: sortable[j][0],
          stopover: true
        });
      }
      var start = this.origin;
      var end = sortable[sortable.length - 1][0];
      this.calcRoute(start, end, waypoints);
    }
  }
  calcRoute(start, end, waypoints) {   //To calculate shortest route
    var request = {
      origin: start,
      destination: end,
      waypoints: waypoints,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING
    };
    this.directionsService.route(request, (response, status) => {
      debugger;
      if (status == google.maps.DirectionsStatus.OK) {
        this.directionsDisplay.setDirections(response);
        var route = response.routes[0];
        this.totalDistance = 0;
        for (var i = 0; i < route.legs.length; i++) {
          this.totalDistance += route.legs[i].distance.value;
        }
        document.getElementById("distance").innerHTML = "Least total Distance for the given route is " + this.totalDistance / 1000 + "km";
      }
    });
  }

  listAllAddress = [];
  private loadData() {
    $('.csLoader').css({ display: 'flex' });
    //this.prescriptionService.getDataForRouting().subscribe(x => {
       this.prescriptionService.getAllWhichAreReadyToPickup().subscribe(x => {
      if (x.data) {
        x.data.forEach(element => {
          var addr = element.deliveryAddress.streetAddress + " " + element.deliveryAddress.city + " " + element.deliveryAddress.postalCode + " Canada";
          // this.listAddress.push(addr);
          this.listAllAddress.push({ selected: false, addr: addr, name: element.deliveryAddress.firstName + " " + element.deliveryAddress.lastName });
        });
        $('.csLoader').css({ display: 'none' });
      }
    });
  }
  continueWithSelectedAddress() {
    if (this.listAllAddress.filter(x => x.selected).length <= 1) {
      this._snackBar.open("Select two or more address", "OK", {
        duration: 4000,
      });
    }
    else {
      this.listAllAddress.filter(x => x.selected).forEach(element => {
        this.listAddress.push(element.addr);
      });
    }
  }
  reSelectAddress(){
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }



  mapSetting = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffeb3b"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b4934"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];
}
