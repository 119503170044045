import { Component, OnInit } from '@angular/core';
import { PharmacyService } from '../service/pharmacy.service';
import { AppointmentService } from '../service/appointment.service';
import { GlobalConstants } from '../shared/global-constants';
import { ThrowStmt } from '@angular/compiler';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CreateNewConfDialogComponent } from './create-new-conf-dialog/create-new-conf-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  // pharmacies: Array<Pharmacy>;
  // currentPharmacy: string = '';
  // schedules: PharmacySchedule;
  // currentPharmacyID: string = '';

  constructor(private pharmacyService: PharmacyService,
    private appointmentService: AppointmentService, public dialog: MatDialog, private _snackBar: MatSnackBar, private router: Router) {


  }
  services = [];
  ngOnInit() {
    this.getAllServices();
  }

  private getAllServices() {
    this.appointmentService.getScheduleForPharmacy(GlobalConstants.selectedPharmacyID).subscribe(data => {

      this.services = data;
      console.clear();
      console.log(this.services);
    });
  }

  createNew() {
    const dialogRef = this.dialog.open(CreateNewConfDialogComponent, {
      width: '1024px',
      data: null,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.pharmacyID = GlobalConstants.selectedPharmacyID;
        this.appointmentService.addNewService(result).subscribe(x => {
          console.log(x);
          this.getAllServices();
        }, error => {
          console.log(error);
        });
      }
      console.log(result);
    });
  }
  currentSelectedSerivice = null;
  editService(service) {
    this.currentSelectedSerivice = service;
    const dialogRef = this.dialog.open(CreateNewConfDialogComponent, {
      width: '920px',
      data: this.currentSelectedSerivice,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.currentSelectedSerivice = result;
       
        this.appointmentService.addNewService(this.currentSelectedSerivice).subscribe(x => {
          this._snackBar.open("Service saved successfully.", "Info", {
            duration: 4000,
          });
          this.getAllServices();
        }, error => {
          this._snackBar.open("Something went wrong try again.", "Error", {
            duration: 4000,
          });
          console.log(error);
        });
      }
      console.log(result);
    });
  }
  refreshComponent(): void {
    // save current route first
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });

  }
}

export interface Pharmacy {
  id: string;
  name: string;

}