import { Patient, User, UserRole } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { RoutLink } from './models/rout.link.model';

export class GlobalConstants {
    public static isLoggedIn: boolean = false;

    public static currentUser: Patient;

    public static selectedPharmacyID: string;

    static readonly CURRENT_USER = "myRxAdminCurrentUser";
    static readonly CURRENT_LOCAL_LOGIN_TIME = "myRxAdminCurrentUserLoingTime";
    static readonly CURRENT_SESSION_ID = "myRxAdminCurrentSession_Id";

    //public static currentUser: User;
    public static getcurrentUser(): User | null {
        return localStorage.getItem(GlobalConstants.CURRENT_USER) ? JSON.parse(localStorage.getItem(GlobalConstants.CURRENT_USER) || "{}") as User : null;
    };
    public static clearCurrentUserSession() {
        localStorage.removeItem(GlobalConstants.CURRENT_USER);
        GlobalConstants.deleteLoginCookie();
    }

    public static getDeviceId(): string {
        const deviceId = localStorage.getItem('myLocalRxAdminDeviceId') || GlobalConstants.uuidv4();
        localStorage.setItem('myLocalRxAdminDeviceId', deviceId);
        return deviceId;

    }
    public static getCurrentSessionId(forceNew = false): string {

        var CURRENT_SESSION_ID = localStorage.getItem('CURRENT_SESSION_ID') || GlobalConstants.uuidv4();
        if (forceNew) {
            CURRENT_SESSION_ID = GlobalConstants.uuidv4();
        }
        localStorage.setItem('CURRENT_SESSION_ID', CURRENT_SESSION_ID);
        return CURRENT_SESSION_ID;

    }
    public static uuidv4(): string {
        var rand = Math.random;
        var nbr, randStr = "";
        do {
            randStr += (nbr = rand()).toString(16).substr(3, 6);
        } while (randStr.length < 30);
        return (
            randStr.substr(0, 8) + "-" +
            randStr.substr(8, 4) + "-4" +
            randStr.substr(12, 3) + "-" +
            ((nbr * 4 | 0) + 8).toString(16) + // [89ab]
            randStr.substr(15, 3) + "-" +
            randStr.substr(18, 12)
        );
    }

    public static createCookieLoginCookie(max: boolean) {
        var name = "login";;
        var value = "login";
        var path = "/";
        var expires = "";
        var date = new Date();
        var midnight = new Date(max ? date.getFullYear() + 1 : date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        // var midnight = new Date(max ? date.getFullYear() + 1 : date.getFullYear(),date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + 1, 0);
        console.log(midnight);
        expires = "; expires=" + midnight.toUTCString();
        document.cookie = name + "=" + value + expires + "; path=" + path;
    }
    public static deleteLoginCookie() {
        var cvalue = 'login';
        var cname = 'login';
        var expires = 0;
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    public static getLoginCookie(): String {
        var name = "login=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        localStorage.clear();
        return "";
    }



    public static routLinks: RoutLink[] = [
        {
            enable: true,
            label: 'Dashboard',
            link: './dashboard',
            index: 0,
            allowedRoles: [UserRole.ADMIN, UserRole.USER]

        }, {
            enable: true,
            label: 'Prescriptions',
            link: './prescription/prescription-list',
            index: 1,
            allowedRoles: [UserRole.ADMIN, UserRole.USER]
        },
        {
            enable: true,
            label: 'Appointments',
            link: './appointment',
            index: 2,
            allowedRoles: [UserRole.ADMIN, UserRole.USER]
        },
        ,
        {
            enable: true,
            label: 'Waiting List',
            link: './waiting-list',
            index: 3,
            allowedRoles: [UserRole.ADMIN, UserRole.USER]
        }

        , {
            enable: true,
            label: 'Configuration',
            link: './configuration',
            index: 4
            , allowedRoles: [UserRole.ADMIN, UserRole.USER]
        },
        {
            enable: true,
            label: 'Manage Users',
            link: './manage-users',
            index: 5
            , allowedRoles: [UserRole.ADMIN]
        },
        {
            enable: true,
            label: 'My Orders',
            link: './my-order',
            index: 9
            , allowedRoles: [UserRole.ADMIN, UserRole.USER]
        },
        // {
        //     enable: true,
        //     label: 'Minor Ailments',
        //     link: './minor-ailments',
        //     index: 9
        //     , allowedRoles: [UserRole.ADMIN, UserRole.USER]
        // },
        // {
        //     enable: true,
        //     label: 'Minor Ailments Prescription',
        //     link: './minorprescriptionhistory',
        //     index: 10
        //     , allowedRoles: [UserRole.ADMIN, UserRole.USER]
        // },
        {
            enable: true,
            label: 'Ready to pickup',
            link: './ready-to-pickup',
            index: 6
            , allowedRoles: [UserRole.DELIVERY]
        },
        {
            enable: true,
            label: 'Delivery Routing',
            link: './delivery-routing',
            index: 7
            , allowedRoles: [UserRole.DELIVERY]
        },
        {
            enable: true,
            label: 'My pharmacies',
            link: 'my-pharmacies',
            index: 8
            , allowedRoles: [UserRole.DELIVERY]
        }
    ]
}