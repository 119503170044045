import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { GlobalConstants } from './global-constants';
import { Patient, User } from './models/user.model';

@Injectable()
export class UserResolver implements Resolve<User> {

  constructor(private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Promise<User> {


    return new Promise((resolve, reject) => {

      var currentUser = GlobalConstants.getcurrentUser();
      if (currentUser == undefined || GlobalConstants.getLoginCookie() == "") {
        if (currentUser) {
          GlobalConstants.clearCurrentUserSession();
          window.location.href = window.location.origin;
        }
        this.router.navigate(['login']);
        return reject("Please login");

      }
      else {
        if (localStorage.getItem(GlobalConstants.CURRENT_LOCAL_LOGIN_TIME) == null) {
          localStorage.setItem(GlobalConstants.CURRENT_LOCAL_LOGIN_TIME, new Date().getTime().toString());
        }
        return resolve(currentUser);
      }
    });
  }
}
