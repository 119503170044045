import { Pipe, PipeTransform } from '@angular/core';
import { Pharmacy } from '../models/pharmacy.model';

@Pipe({
  name: 'pharmacyName'
})
export class PharmacyNamePipe implements PipeTransform {

  transform(value: string, pharmacies: Pharmacy[]): any {
    var name = "";
    var f = pharmacies.find(x => x.id == value);
    if(!f){
      return name;
    }
    var fName = f ? f.name : "External Pharmacy";
    var pin = f ? f.pin : "N/A";
    name += `<span class="fnamepin" title="${fName} ${pin}"><span class="name">${fName.substring(0, 5)}...</span>${pin}</span>`;
    fName + ", " + pin;
    return name;
  }

}
