import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {


  message: string = "Are you sure?";
  confirmButtonText = "Yes";
  cancelButtonText = "Cancel";
  isRadio = false;
  isInputRequired = false;
  userInput = "";
  placeholder = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.isRadio = data.isRadio || this.isRadio;
      this.isInputRequired = data.isInputRequired || this.isInputRequired;
      this.placeholder = data.placeholder || "";
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  ngOnInit() {
  }
  onConfirmClick(val: boolean): void {
    if (this.isInputRequired && val) {
      if (this.userInput) {
        this.dialogRef.close(this.userInput);
      }
    } else {
      this.dialogRef.close(val);
    }
  }
  yeNoChange(event: MatRadioChange) {
    debugger;
    console.log(event.value);
    this.dialogRef.close(event.value == "0" ? true : false);
  }

}
