import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/service/user.service';
declare var $: any
@Component({
  selector: 'app-eanble-two-fa',
  templateUrl: './eanble-two-fa.component.html',
  styleUrls: ['./eanble-two-fa.component.css']
})
export class EanbleTwoFAComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any, private userService: UserService, private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EanbleTwoFAComponent>) { }

  currentUser: any = null;
  ngOnInit() {

    this.userService.logInSuccess.subscribe(x => {
      this.currentUser = x;
    });
  }
  cancel() {
    this.dialogRef.close();
  }
  step = 0;
  device = "android";
   gAuth: any;
  nextStep() {
    if (this.step == 0) {
      this.step = 1;
    }
     if (this.step == 1) {
      $('.csLoader').css({ display: 'flex' });
      this.userService.enbaleTwoFa(this.currentUser.username).subscribe(x => {
        this.gAuth = x.data;
        this.gAuth.username = this.currentUser.username;
        $('.csLoader').css({ display: 'none' });
        $('#2faDiv').html(`<img src="data:image/JPEG;base64,${x.successMessage}" style='width:100%'/>`);
        this.step = 2;
      }, err => {
        $('.csLoader').css({ display: 'none' });
        this._snackBar.open("Something went wrong try again...", "Error", {
          duration: 4000,
        });
        this.dialogRef.close();
      });
     }

    if (this.step == 2) {
      $('.csLoader').css({ display: 'flex' });
      this.userService.saveGoogleAuthkey(this.gAuth).subscribe(x => {

        $('.csLoader').css({ display: 'none' });

        this._snackBar.open("2 factor aunthentication enabled successfully.", "Success", {
          duration: 4000,
        });
        this.dialogRef.close();
      }, err => {
        this._snackBar.open("Something went wrong try again...", "Error", {
          duration: 4000,
        });
        this.dialogRef.close();
      });
    }
  }

}
