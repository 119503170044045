import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/internal/operators/timeout';
import { AilmentsDialogComponentComponent } from '../ailments-dialog-component/ailments-dialog-component.component';
import { UserService } from '../service/user.service';
@Component({
  selector: 'app-minor-ailments',
  templateUrl: './minor-ailments.component.html',
  styleUrls: ['./minor-ailments.component.css']
})
export class MinorAilmentsComponent implements OnInit {
  prescriptions :Array<any>;
  answerForm: FormGroup;
  mapsFlowanswerForm: FormGroup;
  mapsflowDecision=['Select Pharmacist Decision','Prescribed','Referred to Doctor'];
  ans1st;
  ans2nd;
  name: string;
  algorithmName: Array<any>; 
  showAilments: boolean = false;
  showAilments3: boolean = false;
  showAilments2: boolean = false;
  showfirstques:boolean = false;
  show3rdques:boolean = false;
 show2ndques : boolean = false;
 empList: Array<any> = [];
 patient_factor : Array<any>;

 myForm: FormGroup;

 red_flag_symptoms : Array<any>;

symptoms_factor : Array<any>;
  algorithmList: Array<any>;
  settingFormOne: FormGroup;
  currentAlgorithm: any;
  showul1: boolean;
  showul2 :boolean= false ;
  showul3: boolean=false;
  showAilments4: any;
  showul4: boolean;
  firstques: string;
  secques: string;
  thirdques: string;
  mildPrescription: { display_name: string; prescription_id: string; answer_key: string; follow_up_days: any; prescription_option: string[]; };
  moderatePrescription: { display_name: string; prescription_id: string; answer_key: string; follow_up_days: any; prescription_option: string[]; };
  severePrescription: { display_name: string; prescription_id: string; answer_key: string; follow_up_days: any; prescription_option: string[]; };
  patientFactors: any;
  redFlagSymptoms: any;
  symtomsFactors: any;
  assesmentPrescription: any;
  assesmentAppointId: string ;
  assesmentTableDocumentId:string;
  insertAssesmentData: { id: string; appointment_id: string; assessment_status: string; follow_up_days: number; decision: string; follow_up_date: Date; follow_up_status: string; patientFactors: any; prescription: any; redFlagSymptoms: any; symtomsFactors: any;mapflow_pharmacy_notes:any; };
  decision: any;
  followUpDays: number;
  mapsFlowNote: any;
  decisionValue: string[];
  defaultOption: string;
  result: any;

  constructor(public fb: FormBuilder,public dialog: MatDialog,private router: Router,public userservice:UserService) {
   this.decisionValue=['Select Pharmacist Decision','Prescribed','Referred to Doctor'];
    this.answerForm = fb.group({
      ans1st: ['', Validators.required],
      ans2nd: ['', Validators.required],
      ans3rd: ['', Validators.required]
    });
    this.mapsFlowanswerForm= fb.group({
      mapsFlowdecision: ["", [Validators.required, Validators.min(1)]],
      mapsFlowNote: ['', Validators.required],
      // ans3rd: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.defaultOption='Select Pharmacist Decision';
    this.mapsFlowanswerForm= this.fb.group({
      mapsFlowdecision: ["", [Validators.required, Validators.min(1)]],
      mapsFlowNote: ['', Validators.required],
      // ans3rd: ['', Validators.required]
    });
    
    this.decisionValue=['Select Pharmacist Decision','Prescribed','Referred to Doctor'];
    this.showul1=false;
    this.showul2=false;
    this.showul3=false;
    this.showul4=false;
    this.showAilments=false;

  this.myForm = new FormGroup({
    1: new FormControl(''),
    2: new FormControl(''),
    3: new FormControl('')
  });

this.getMinorAlgorithm();


this.settingFormOne = this.fb.group({
  algorithmNameForm: ['', Validators.required],
});
  }
 
  backtodashboard() {
    this.router.navigate(['/appointment']);

}


getPrescriptions(selectedAlgorithm){
  
  this.userservice.getAllPrescription(selectedAlgorithm.algorithm_name).subscribe({
    next: data => {
      this.assesmentPrescription='';
this.prescriptions = data;
  this.prescriptions.forEach(element => {
    this.mildPrescription = element.prescription1;
    this.moderatePrescription = element.prescription2;
    this.severePrescription =element.prescription3;
 
   });



    }})


  

}


getfirstQuestion(selectedAlgorithm){
  this.patient_factor=[];
  this.userservice.getPatientFactor(selectedAlgorithm.algorithm_name).subscribe({
    next: data => {
      
     
      data.forEach(element => {
this.patientFactors = element;
this.firstques='Q-1)' + element.question 
this.patient_factor=element.option;

this.showAilments =true;
this.showul1=true;
this.showul2=false;
this.showul3=false;
this.showul4=false;

      });
  
  }

  })

}


getsecondtQuestion(selectedAlgorithm){
  
  this.userservice.getredFlagFactor(selectedAlgorithm.algorithm_name).subscribe({
    next: data => {
      
      data.forEach(element => {
  this.redFlagSymptoms =element;
  this.secques='Q-2)' + element.question ;
this.red_flag_symptoms=element.option;
this.showAilments =true;
this.showul1=false;
this.showul2=true;
this.showul3=false;
this.showul4=false;

      });
  
  }

  })

}



getthirdtQuestion(selectedAlgorithm){
  
  this.userservice.getsymptoms_factor(selectedAlgorithm.algorithm_name).subscribe({
    next: data => {
      
      data.forEach(element => {
        this.symtomsFactors = element;
  this.thirdques='Q-3)' + element.question 
this.symptoms_factor=element.option;

this.showAilments =true;
this.showul1=false;
this.showul2=false;
this.showul3=true;
this.showul4=false;



      });
      this.getPrescriptions(selectedAlgorithm);

  }

  

  })

}
  
  getMinorAlgorithm() {
    this.userservice.getMinorAilments().subscribe({
      next: data => {
        // console.log("minorAlogorithm name=",data)
        this.algorithmName =data

    
    }
    })
  
  }


  sortByName(a: any, b: any) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }


  onChangeVal(deviceValue){
    this.getfirstQuestion(deviceValue);

  }

  getvalue(d){
    this.decision ='';
    this.showAilments = false;
    console.log(d,this.currentAlgorithm);
    if(d == 'No'){
this.getsecondtQuestion(this.currentAlgorithm)

    }
    if(d == 'Yes'){
      this.patientFactors={};
      this.redFlagSymptoms={};
      this.symtomsFactors={};
      this.showAilments = true;
      this.decision='Refer to Doctor';
      this.insertdata('null');
    }
    this.showAilments2 =d;
    this.showul1=false;
    this.showul2=true;
  }

  getvalue2nd(d){
    this.decision='';
    this.showAilments = false;
    console.log(d,this.currentAlgorithm);
    if(d == 'No'){
      this.getthirdtQuestion(this.currentAlgorithm)
      
          }
          if(d == 'Yes'){
            this.redFlagSymptoms={};
      this.symtomsFactors={};
            this.showAilments = true;
            this.decision='Refer to Doctor';
            this.insertdata('null');
          }
    this.showAilments3 = d;
    this.showul1=false;
    this.showul2=false;
    this.showul3=true;
  }

  getvalue3rd(d){
    this.decision='';
    console.log(d);
    if(d == 'Yes'){
      this.symtomsFactors={};
      this.showAilments = true;
      this.decision='Refer to Doctor';
      this.insertdata('null');
    }
    this.showAilments4 = d;
    this.showul1=false;
    this.showul2=false;
    this.showul3=false;
    this.showul4=true;
  }
  openDialog() {
this.getfirstQuestion(this.currentAlgorithm);
  
} 
openDialog2() {
 this.getsecondtQuestion(this.currentAlgorithm)
  
}
openDialog3() {
this.getthirdtQuestion(this.currentAlgorithm);


}


insertdata(prescriptionType){
  this.decision=''
  console.log("prescriptionType",prescriptionType)
  this.assesmentPrescription =prescriptionType;
  if(this.assesmentPrescription == 'null'){
    this.decision='Refer to Doctor';
    this.followUpDays =0;
    this.assesmentPrescription={}
  }
  else{
    this.decision='Prescribed';
    this.followUpDays=prescriptionType.follow_up_days;
  }
  
this.assesmentAppointId='';
this.assesmentTableDocumentId='';
  let date: Date = new Date();
  this.assesmentAppointId = localStorage.getItem("assesment_id")
  this.assesmentTableDocumentId = localStorage.getItem("appointment_id");
  console.log("assesmentTableDocumentId=",this.assesmentTableDocumentId,'assesmentAppointId=',this.assesmentAppointId);
//   this.insertAssesmentData ={id:this.assesmentTableDocumentId , appointment_id: this.assesmentAppointId , assessment_status: 'completed', follow_up_days: this.followUpDays, decision: this.decision,
//    follow_up_date:date, follow_up_status: 'null', patientFactors:this.patientFactors , prescription:this.assesmentPrescription,
//     redFlagSymptoms:this.redFlagSymptoms , symtomsFactors: this.symtomsFactors };
    
//     // console.log('result addStatusofBookedMinorailments=', this.insertAssesmentData);
// this.userservice.insertAssesmentDocument(this.insertAssesmentData).subscribe(resultData => {
//   console.log('result addStatusofBookedMinorailments=', resultData);
// }) 

}


onSubmitmapsFlow() {

  let date: Date = new Date();
  this.assesmentAppointId = localStorage.getItem("assesment_id")
  this.assesmentTableDocumentId = localStorage.getItem("appointment_id");
 this.decision = this.mapsFlowanswerForm.get('mapsFlowdecision').value ;
 console.log("this.decision",this.decision);
this.mapsFlowNote = this.mapsFlowanswerForm.get('mapsFlowNote').value ;
console.log("mapsFlowNote=",this.mapsFlowNote);

  this.insertAssesmentData ={id:this.assesmentTableDocumentId , appointment_id: this.assesmentAppointId , assessment_status: 'completed', follow_up_days:0, decision: this.decision,
   follow_up_date:date, follow_up_status: 'null', patientFactors:{} , prescription:{},
    redFlagSymptoms:{}, symtomsFactors: {}, mapflow_pharmacy_notes:this.mapsFlowNote };
    
    // console.log('result addStatusofBookedMinorailments=', this.insertAssesmentData);
    this.userservice.insertAssesmentDocument(this.insertAssesmentData).subscribe(resultData => {
      console.log('result addStatusofBookedMinorailments=', resultData);
      this.result= resultData;
      // if(resultData){
      //   window.alert("Pharmacist's Decision Submitted Successfully");

      // }
    }) 

}

}
