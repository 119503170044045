import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PharmacyService } from 'src/app/service/pharmacy.service';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrescriptionService } from '../../service/prescription.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PrescriptionImagesComponent } from '../prescription-images/prescription-images.component';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Pharmacy } from 'src/app/shared/models/pharmacy.model';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';


declare var $: any;

export interface Medicine {
  medicine_name: string;
  medicine_type: string;
  ordered_quantity: number;
  delivered_quantity: number;
  fulfilled_status: string;
}
export interface imagesArray{
  src:string;
  alt:any;
}

@Component({
  selector: 'app-prescription-table',
  templateUrl: './prescription-table.component.html',
  styleUrls: ['./prescription-table.component.css']
})
export class PrescriptionTableComponent implements OnInit {
  smallImageWidth = 150;
  smallImageHeight = 150;
  confirmationDialogRef: MatDialogRef<any>;
  imagesdialogRef: MatDialogRef<any>;
  tableData: Medicine[] = [];
  displayedColumns: string[] = ['medicine_name', 'medicine_type', 'ordered_quantity', 'delivered_quantity', 'delete', 'fulfilled_status'];
  dataSource: MatTableDataSource<Medicine>;
  selectedRows: Medicine[] = [];
  selectAll: boolean = false;
  addForm: FormGroup;
  columnValues: any[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  prescriptionId: string;
  psType: string;
  prescription: any;
  extractText: any;
  configuredkeyword: any;
  medicine_fulfillment_status: any;
  resulttext: string[];
  medicinesArray: any[] = [];
  showform: boolean = false;
  prescriptionArray: any[] = [];
  tableLength: string;
  userSelectPharmacy: Pharmacy = null;
  images: imagesArray [
   
  ];

  currentIndex = 0;
  prescriptionArrayLength: number;
  sub: any;
  constructor(private formBuilder: FormBuilder, private prescriptionService: PrescriptionService, private dialog: MatDialog,
    private pharmacyService: PharmacyService, private userService: UserService,private _snackBar: MatSnackBar,private route: ActivatedRoute,) {
    this.dataSource = new MatTableDataSource<Medicine>(this.tableData);
    this.addForm = this.formBuilder.group({
      medicine_name: ['', Validators.required],
      medicine_type: ['', Validators.required],
      delivered_quantity: ['', Validators.required],
      ordered_quantity: ['', Validators.required],
      fulfilled_status: ['NotFullfiled', Validators.required],
    });
  }

  ngOnInit(): void {
    this.showform = false;
    this.dataSource.data = [];
    this.tableData = [];
    this.medicinesArray = [];
    this.prescriptionId = '';
    this.extractText = '';
    this.configuredkeyword = '';
    if (GlobalConstants.selectedPharmacyID) {
      // console.log("selected pharmacy=",GlobalConstants.selectedPharmacyID);
      this.pharmacyService.getAll(false).subscribe(x => {
        this.userSelectPharmacy = x.find(x => x.id == GlobalConstants.selectedPharmacyID);
        // console.log("Pharmacy level", this.userSelectPharmacy, this.userSelectPharmacy.configuredkeyword+' , prescription_custom_status = '+this.userSelectPharmacy.prescription_custom_status);
        this.configuredkeyword = this.userSelectPharmacy.configuredkeyword;
        if (this.configuredkeyword == null || this.configuredkeyword == 'medicine' || this.configuredkeyword == 'mechine') {
          this.configuredkeyword = 'syp'
        }else if(this.configuredkeyword == 'Medicine Name'){
          this.configuredkeyword == 'Medicine Name';
        } 
        
      });

    }

    this.prescriptionId = localStorage.getItem("prescriptionId");
    console.log("prescriptionId=", this.prescriptionId)
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      localStorage.setItem("prescriptionId",params.id);
      localStorage.setItem("pstype",params.psType)
      this.psType = params.psType;
      if (id) {
        this.refreshPrescription(id);
      }
    });
    if (this.prescriptionId) {
      // $('.csLoader').css({ display: 'flex' });
      console.log("param type",this.psType)

      this.prescriptionService.get(this.prescriptionId, this.psType == "archived").subscribe((prescription: any) => {

        if (prescription) {
          console.log("prescription data=", prescription);
          for (const key in prescription) {
            if (key === "extractText") {
              this.extractText = prescription[key];
            }

            // if (key === "configuredkeyword") {
            //     this.configuredkeyword = prescription[key];
            //     if(this.configuredkeyword == null || this.configuredkeyword == 'medicine'){
            //       this.configuredkeyword = 'syp'
            //     }
            //     console.log("configuredkeyword=",this.configuredkeyword)

            // }

            if (key === "medicine_fulfillment_status") {
              this.medicine_fulfillment_status = prescription[key];
              // console.log("medicine_fulfillment_status=",this.medicine_fulfillment_status)

            }

            if (key === "medicines") {
              this.medicinesArray = prescription[key];
              // console.log("medicine_fulfillment_status=",this.medicine_fulfillment_status)
            }
            if (key === "prescriptionLinks" && prescription[key] !=null) {
              this.prescriptionArray = prescription[key];
              this.prescriptionArrayLength = this.prescriptionArray.length 
              console.log("prescription link",prescription[key])

            }

          }

          //  console.log("medicine_fulfillment_status=",this.medicine_fulfillment_status,"configuredkeyword=",this.configuredkeyword,"foundValue=",this.extractText)

          if (this.medicine_fulfillment_status === "Notfulfilled") {
            const result = this.splitSentenceAfterKeyword(this.extractText, this.configuredkeyword);
            // console.log("result=", result);
            this.resulttext = result.filter((word, index) => result.indexOf(word) === index)
            // console.log("resulttext===",this.resulttext);
            this.resulttext.forEach(element => {
              this.tableData.push({ medicine_name: element, medicine_type: '', ordered_quantity: 0, delivered_quantity: 0, fulfilled_status: "NotFulfilled" })

            });

            // console.log(this.tableData)
            this.dataSource.data = this.tableData;

          }
          else {
            this.tableData = this.medicinesArray;
            console.log(this.tableData)
            this.dataSource.data = this.tableData;
          }



          // this.prescription = prescription;
          // console.log(`pharmacy with id '${this.prescriptionId}' found` + prescription,);
        } else {
          console.log(`pharmacy with id '${this.prescriptionId}' not found, returning to list`);

        }
        $('.csLoader').css({ display: 'none' });
      });
    }

    // const sentence = "URTI\nsyp\nRR-22/min\nRS-BIL PEE\nDate: 20-9-2022\nsgp Medicine CALPOL (250/5) 4 ML Q6H x 3\nDELCON 3 mL TDS x";
    // const keyword = "Medicine";

  }
  showNextImage() {
    if (this.currentIndex < this.prescriptionArray.length - 1) {
      this.currentIndex++;
    }
  }

  showPreviousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }
  getColumnValues() {
    this.columnValues = this.dataSource.data.map((row: any) => {
      return {
        medicine_name: row.medicine_name,
        medicine_type: row.medicine_type,
        ordered_quantity: row.ordered_quantity,
        delivered_quantity: row.delivered_quantity,
        fulfilled_status: row.fulfilled_status,
      };
    });

    console.log('Column Values:', this.columnValues);
    const i = 1;
    this.tableLength = ''
    console.log("")
    this.columnValues.forEach(element => {

      console.log("medicine.fulfilmentstatus", element.fulfilled_status)
    });
    this.updateMedicineByprescription(this.columnValues)
  }



  splitSentenceAfterKeyword(sentence: string, keyword: string): string[] {
    const index = sentence.indexOf(keyword);

    if (index !== -1) {
      // const firstPart = sentence.slice(0, index + keyword.length);
      const secondPart = sentence.slice(index + keyword.length);
      const myarray = secondPart.split('\\n')

      // return [firstPart.trim(), secondPart.trim()];
      return myarray

    }

    // Return the original sentence if the keyword is not found
    // return sentence;
  }

  openAddMedicineDialog() {

    this.showform = true;

  }

  compareDeliveredQuantity(row: Medicine) {
    if (row.delivered_quantity === row.ordered_quantity) {
      row.fulfilled_status = 'Fulfilled';
    } else if (row.delivered_quantity < row.ordered_quantity && row.delivered_quantity > 0) {
      row.fulfilled_status = 'PartiallyFulfilled';
    } else if (row.delivered_quantity === 0 || row.delivered_quantity == null || row.delivered_quantity == undefined) {
      row.fulfilled_status = 'NotFulfilled';
    }
  }
  updateMedicineByprescription(medicine) {
    console.log("medicine.fulfilmentstatus", medicine.fulfilled_status)

    const updateData =
    {
      id: this.prescriptionId,
      medicines: medicine,

      medicine_fulfillment_status: "Partially"
    }
    this.prescriptionService.updateMedicine(updateData).subscribe(resultData => {
      console.log('result addStatusofBookedMinorailments=', resultData);
      const result = resultData;
      if (result) {
        this._snackBar.open("Medicine added Successfully", "Ok", {
          duration: 5000,
        });

      }
    })
  }
  openDeleteDialog(ref: TemplateRef<any>, row) {
    this.confirmationDialogRef = this.dialog.open(ref, {
      width: '400px',
      disableClose: true
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // Delete the row
        this.deleteRow(row);
      }
    });
  }

  zoomImage(event: MouseEvent): void {
    const imageElement = event.target as HTMLImageElement;
    imageElement.classList.add('zoomed');
  }

  resetImage(event: MouseEvent): void {
    const imageElement = event.target as HTMLImageElement;
    imageElement.classList.remove('zoomed');
  }

  openImageDialog(image: string): void {
    this.dialog.open(PrescriptionImagesComponent, {
      width: '650px',
      data: { image }
    });
  }

  onCancel(): void {
    this.confirmationDialogRef.close(false);
  }

  onConfirm(): void {
    this.confirmationDialogRef.close(true);
  }



  refreshPrescription(id: string) {
    $('.csLoader').css({ display: 'flex' });
    console.log("psType",this.psType)
    this.prescriptionService.get(id, this.psType == "archived").subscribe((prescription: any) => {

      if (prescription) {
        console.log("prescription data=", prescription);
        for (const key in prescription) {
          if (key === "extractText") {
            this.extractText = prescription[key];
            console.log("foundValue=", this.extractText)
            this.configuredkeyword = 'nsyp';

            this.resulttext = this.splitSentenceAfterKeyword(this.extractText, this.configuredkeyword);
            console.log("after trim medicine", this.resulttext);
            //  const myArray=[] = result.split('\n');
          }

          if (key === "configuredkeyword") {
            this.configuredkeyword = prescription[key];
            if (this.configuredkeyword == null) {
              this.configuredkeyword = 'nsyp'
            }
            console.log("configuredkeyword=", this.configuredkeyword)

          }

          if (key === "medicine_fulfillment_status") {
            this.medicine_fulfillment_status = prescription[key];
            console.log("medicine_fulfillment_status=", this.medicine_fulfillment_status)

          }

        }
        this.prescription = prescription;
        console.log(`pharmacy with id '${id}' found` + prescription,);
      } else {
        console.log(`pharmacy with id '${id}' not found, returning to list`);

      }
      $('.csLoader').css({ display: 'none' });
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }



  isAllSelected(): boolean {
    const numSelected = this.selectedRows.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedRows = this.dataSource.data.slice();
    } else {
      this.selectedRows = [];
    }
  }

  deleteRow(row: Medicine) {
    const index = this.dataSource.data.indexOf(row);
    if (index > -1) {
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
      this.selectedRows = this.selectedRows.filter(item => item !== row);
    }
  }

  addRow() {
    if (this.addForm.valid) {
      const { medicine_name, medicine_type, ordered_quantity, delivered_quantity, fulfilled_status } = this.addForm.value;
      const newRow: Medicine = { medicine_name, medicine_type, ordered_quantity, delivered_quantity, fulfilled_status };
      this.dataSource.data.push(newRow);
      this.dataSource._updateChangeSubscription();
      this.addForm.reset();
      this.showform = false;
    }
  }

  toggleSelection(row: Medicine) {
    const index = this.selectedRows.findIndex(item => item === row);
    if (index > -1) {

      this.selectedRows.splice(index, 1); // Deselect the row
      console.log('deselected row', this.selectedRows, row)
    } else {
      this.selectedRows.push(row); // Select the row
      console.log('selected row', this.selectedRows, row)
    }
  }

  isSelected(row: Medicine) {
    // console.log(this.selectedRows.includes(row),'INCLUDES ROW')


    return this.selectedRows.includes(row);
  }
}