import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { GlobalConstants } from './global-constants';
declare var $: any;
declare function playNotification();
declare var window: Window;
@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging) {
        // this.angularFireMessaging.messages.subscribe(
        //     (_messaging:any) => {
        //         _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //         _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        //         _messaging._next = (payload: any) => {
        //             console.log(payload);
        //             };
        //     }
        // );
    }

    updateToken(userId, token) {
        var fcm = JSON.parse(localStorage.getItem('myLocalRxAdminFcm')) as FCMModel || {
            userId: userId, token: token,
            deviceId: GlobalConstants.getDeviceId(), isSaved: false
        } as FCMModel;
        if (fcm.token !== token) {
            fcm.token = token;
            fcm.isSaved = false;
        }

        this.saveFcmOjbect(fcm);
    }

    saveFcmOjbect(data: FCMModel) {
        localStorage.setItem('myLocalRxAdminFcm', JSON.stringify(data));
    }
    getFcmObject(): FCMModel {
        return JSON.parse(localStorage.getItem('myLocalRxAdminFcm')) as FCMModel;
    }


    async requestPermission(userId): Promise<FCMModel> {
        await this.requestNotificationPermission();
        return new Promise<FCMModel>((resolve, reject) => {
            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    console.log(token);
                    this.updateToken(userId, token);

                    resolve(this.getFcmObject());
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                    reject(err);
                }
            );
        });


    }
    deletToken(token:any) {
        GlobalConstants.deleteLoginCookie();
        return this.angularFireMessaging.deleteToken(token);
    }
    receiveMessage() {
        this.angularFireMessaging.requestPermission.subscribe(x => {

        });
        this.angularFireMessaging.messages.subscribe((payload: any) => {
            console.log('onMessage:', payload);
            if (payload) {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);


                playNotification();
            }
        });

    }
    requestNotificationPermission = async () => {
        const permission = await window.Notification.requestPermission()
        // value of permission can be 'granted', 'default', 'denied'
        // granted: user has accepted the request
        // default: user has dismissed the notification permission popup by clicking on x
        // denied: user has denied the request.
        if (permission !== 'granted') {
            throw new Error('Permission not granted for Notification')
        }
    }
}
export interface FCMModel {
    userId: string;
    token: string;
    deviceId: string;
    isSaved: boolean;
}
interface Window {
    Notification?: any;
}