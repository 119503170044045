import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { PharmacyService } from '../service/pharmacy.service';
import { UserService } from '../service/user.service';
import { GlobalConstants } from '../shared/global-constants';
import { ValidationUtil } from '../shared/ValidationUtil';
import { ConfirmationDialogComponent } from '../shared/view/confirmation-dialog/confirmation-dialog.component';
import { OkOnlyComponent } from '../shared/view/ok-only/ok-only.component';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  validateForm: FormGroup;
  public loginInvalid: boolean;
  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar, private fb: FormBuilder, private userService: UserService, private pharmacyService: PharmacyService, private route: Router) { }

  logInAsDriver = false;
  listVehicles = null;
  ngOnInit() {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required]],
      logInAsDriver: [false],
      vehicleId: ['']
    });
    this.validateForm = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    this.pharmacyService.getAllVehicles().subscribe(x => {
      this.listVehicles = x;
    });
  }
  vehicleError = false;
  validate2Fa = false;
  userName = "";
  onSubmit() {
    this.vehicleError = false;
    if (this.listVehicles && this.logInAsDriver && !this.form.value.vehicleId) {
      this.vehicleError = true;
      return;
    }
    if (this.form.valid) {
      this.loginInvalid = false;
      GlobalConstants.getCurrentSessionId(true);
      this.userService.signin(this.form.value, this.logInAsDriver && this.form.value.vehicleId ? this.form.value.vehicleId : '').subscribe(x => {
        GlobalConstants.deleteLoginCookie();
        setTimeout(() => {

          if (x.errorMessage) {
            this._snackBar.open(x.errorMessage, "Error", {
              duration: 4000,
            });
          }
          else {
            if (x.successMessage && x.successMessage.indexOf("password")) {
              localStorage.setItem("inv_pwd_formate", "false");
            }
            else if (!ValidationUtil.isValidPassword(this.form.value.password)) {
              localStorage.setItem("inv_pwd_formate", "true");
            }
            if (x.data && x.data.token) {
              this.afterLoginScuccess(x.data);
            }
            else if (x.data && !x.data.token && x.data.twoFa) {
              this.userName = this.form.value.username;
              this.validateForm = this.fb.group({
                code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
              });
              this.validate2Fa = true;
            }
          }

        }, 200);

      }, err => {
        this.vehicleError = true;
      });
    }
  }
  validateOtp() {
    this.userService.validateTwoFa(this.userName, this.validateForm.value.code).subscribe(x => {
      if (x.errorMessage) {
        this._snackBar.open(x.errorMessage, "Error", {
          duration: 4000,
        });
      }
      else {
        this.afterLoginScuccess(x.data);
      }
    });
  }

  afterLoginScuccess(x: any) {
    GlobalConstants.createCookieLoginCookie(x.role == 2 ? false : true);
    localStorage.setItem(GlobalConstants.CURRENT_USER, JSON.stringify(x));
    localStorage.setItem(GlobalConstants.CURRENT_LOCAL_LOGIN_TIME, new Date().getTime().toString());
    this.userService.logInSuccess.next(x);
    $("#closeLoginDialog").trigger("click");
    setTimeout(() => {
      if (x.role == 2) {
        this.route.navigate(['/ready-to-pickup']);
      }
      else {
        this.route.navigate(['/dashboard']);
      }
    }, 500);
  }
  forgotPass() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isInputRequired: true,
        isRadio: false,
        message: "Enter your user name",
        placeholder: "User name",
        buttonText: {
          ok: 'Submit',
          cancel: `Cancel`
        }
      }, maxWidth: '420px'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.userService.forgotPassword(result).subscribe(x => {
          var msg = x.errorMessage ? x.errorMessage : x.successMessage;
          var ttl = x.errorMessage ? "Error" : "Information";
          this.dialog.open(OkOnlyComponent, {
            data: {
              message: msg,
              title: ttl
            }, maxWidth: '420px'
          });
        }, err => {

        });
      }
    });

  }

}

