import { Pipe, PipeTransform } from '@angular/core';
import { ConditionTypes } from '../models/schedual.model';

@Pipe({
  name: 'conditionTypes'
})
export class ConditionTypesPipe implements PipeTransform {

  transform(value: number): any {

    if (value == ConditionTypes.EQUALS_TO) {
      return "EQUALS_TO";
    }
    else if (value == ConditionTypes.GREATER_THAN) {
      return "GREATER_THAN";
    }
    else if (value == ConditionTypes.GREATER_THAN_EQUAL) {
      return "GREATER_THAN_EQUAL";
    }
    else if (value == ConditionTypes.LESS_THAN) {
      return "LESS_THAN";
    }
    else if (value == ConditionTypes.LESS_THAN_EQUAL) {
      return "LESS_THAN_EQUAL";
    }

    return "UNKNOWN TYPE";
  }

}
