import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { PharmacyService } from '../../service/pharmacy.service';
import { AppointmentService } from '../../service/appointment.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { DatePipe } from '@angular/common';
import { LocalDateTimePipe } from 'src/app/shared/pipes/local-date-time.pipe';
import { MatDatepickerInputEvent, MatDialog, MatSnackBar } from '@angular/material';
import { CreatePatientComponent } from 'src/app/prescription/prescription-edit/create-patient/create-patient.component';
import { ConfirmationDialogComponent } from 'src/app/shared/view/confirmation-dialog/confirmation-dialog.component';
import { Patient, User } from 'src/app/shared/models/user.model';
import { Appointment } from 'src/app/shared/models/Appointment';
import { UserService } from 'src/app/service/user.service';
import { ActionEventArgs, EventSettingsModel, GroupModel, PopupOpenEventArgs, ScheduleComponent, TimeScaleModel, WorkHoursModel } from '@syncfusion/ej2-angular-schedule';
import { Address } from 'src/app/shared/models/prescription.model';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DeSanitizeMobileNumber } from 'src/app/shared/pipes/DeSanitizeMobileNumber';
import { SelectReasonComponent } from 'src/app/shared/view/select-reason/select-reason.component';
import { Conditions, Service } from 'src/app/shared/models/schedual.model';
import { QuestionaireDialogComponent } from 'src/app/shared/view/questionaire-dialog/questionaire-dialog.component';
import { DynamicConditionValidationService } from 'src/app/service/dynamic-condition-validation.service';

declare var $: any;
@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class AppointmentListComponent implements OnInit {

  @Input() inp_serviceID = "";
  @Input() inp_patient = null;
  @Input() inp_pharmacyID = "";
  @Output() onWaitingAction = new EventEmitter<any>();
  ///
  colors = ["#ce1212", "#150e56", "#93329e", "#007965"];
  public timeScale: TimeScaleModel = { enable: true, interval: 60, slotCount: 4 };
  @ViewChild("scheduleObj", null)
  public scheduleObj: ScheduleComponent;
  startHour = "07:00";
  endHour = "22:00";
  public workHours: WorkHoursModel = { start: '07:00', end: '22:00' };
  public eventSettings: EventSettingsModel = null;
  ///


  dateFilter = new Date();
  appointments: any;
  pharmacies: Array<Pharmacy>;
  currentPharmacyID: string;
  services = null;
  addresses = [];
  listQuestionnaire = [];
  currentAppoinmentId: string;
  constructor(private appointmentService: AppointmentService, private zone: NgZone,
    private pharmacyService: PharmacyService, private datePipe: DatePipe, private localDateTimePipe: LocalDateTimePipe, private _snackBar: MatSnackBar, private dialog: MatDialog, private userService: UserService, private router: Router, private deSanitizeMobileNumber: DeSanitizeMobileNumber,
    private csValidation: DynamicConditionValidationService) {


  }
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();

  onDateChange(): void {
    this.dateChange.emit();
    this.getShechdualForDay();
  }
  ngOnInit() {
    $('.csLoader').css({ display: 'flex' });
    if (GlobalConstants.selectedPharmacyID) {
      // console.log("selected pharmacy=",GlobalConstants.selectedPharmacyID)

      this.appointmentService.getScheduleForPharmacy(GlobalConstants.selectedPharmacyID).subscribe(x => {
        // console.log('SheduleDetail=',x);
        $('.csLoader').css({ display: 'none' });

        this.services = x;
        this.listServices = [];
        this.selectedServiceID = "-1";
        this.selectedDate = null;
        var i = -1;
        this.services.forEach(data => {
          // console.log("dataaaa",data)
          i++;
          if (i == this.colors.length) {
            i = 0;
          }
          this.listServices.push({
            id: data.id,
            serviceType: data.serviceType,
            serviceProvider:data.serviceProvider,
            endDate: data.endDate,
            startDate: data.startDate,
            name: data.serviceName,
            minorAilment_type:data.minorAilment_type,
            conditions: data.conditions,
            slotDuration: data.slotDuration,
            isActive: data.isActive,
            appointment_note: data.appointment_note,
            // timeslots: data.services[0].schedules.timeslots,
            daysOfTheWeek: data.daysOfTheWeek,
            color: this.colors[i],
          });
        });
        this.getShechdualForDay();
      }, error => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }
  listBooked = [];
  getShechdualForDay() {
    $('.csLoader').css({ display: 'flex' });
    var currentViewDates = this.scheduleObj.getCurrentViewDates();
    // console.log("currentViewDates=",currentViewDates,GlobalConstants.selectedPharmacyID)
    this.appointmentService.getAppointmentForDay(GlobalConstants.selectedPharmacyID, this.datePipe.transform(currentViewDates[0], "MM/dd/yyyy"), this.datePipe.transform(currentViewDates[currentViewDates.length - 1], "MM/dd/yyyy"), this.selectedServiceID).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.listBooked = x;
      // console.log("AppointmentDetail===",this.listBooked);

      this.RenderData();
      if (this.inp_patient != null) {
        this.bookAppointmentForWaitingLits();
      }
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }



  selectedServiceID = "-1";
  listServices: any[] = [];
  public group: GroupModel = {
    resources: ['Services']
  };
  public allowMultipleOwner: Boolean = true
  RenderData() {
    var data = [];
    this.workWeekDays = [];
    this.resourceDataSource = [];
    this.listServices.forEach(se => {
      this.resourceDataSource.push({
        Text: se.name, Color: se.color, Id: se.id,
        serviceProvider:se.serviceProvider,
        isActive: se.isActive,
        endDate: this.localDateTimePipe.transform(se.endDate),
        Count: this.listBooked.filter(li => li.serviceID == se.id && li.status == 0).length + " appointments",
      });
    });
    // var yesterdayDate = new Date();
    // yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    // var temp = this.resourceDataSource.filter(x => x.endDate > yesterdayDate && x.Text);
    // debugger;
    // if (temp.length > 0) {
    //   this.resourceDataSource = temp;
    // }
    this.listBooked.forEach(ele => {
      if (ele.status != 1) {
        var se = this.listServices.find(x => x.id == ele.serviceID);
        if (se) {
          var endTime = this.localDateTimePipe.transform(ele.timeslot);
          endTime.setMinutes(endTime.getMinutes() + se.slotDuration);
          data.push(
            {
              id: ele.id,
              cardiaId: ele.cardiaId,
              cardiaStatus: ele.cardiaStatus,
              endDate: se.endDate,
              startDate: se.startDate,
              dateOfBirth: ele.dateOfBirth,
              patientID: ele.patientID,
              serviceId: ele.serviceID,
              serviceProvider:se.serviceProvider,
              pharmacyID: ele.pharmacyID,
              firstName: ele.address.firstName,
              lastName: ele.address.lastName,
              patientName: ele.address.firstName + " " + ele.address.lastName,
              startTime: this.localDateTimePipe.transform(ele.timeslot),
              endTime: endTime,
              isAllDay: false,
              serviceName: se.name + "  " + ele.minorAilment_type,
              address: this.userAddress(ele.address),
              note: ele.note ? ele.note : "",
              phoneNumber: ele.phoneNumber,
              questionnaire: ele.questionnaire,
              serviceType: se.serviceType,
              appointment_type: ele.appointment_type,
              appointment_note: ele.appointment_note,
             appointment_status: ele.appointment_status,
             appointment_mob: ele.appointment_mob,
            });
        }
      }
    });

    this.eventSettings = {
     
      dataSource: data,
      fields: {
        isReadonly: "true",
        id: 'id',
        subject: {
          name: 'patientName'
        },
        isAllDay: {
          name: 'isAllDay'
        },
        startTime: {
          name: 'startTime'
        },
        endTime: {
          name: 'endTime'
        },
      }
    };
  }
  public resourceDataSource = null;
  workWeekDays = [0, 1, 2, 3, 4, 5, 6];

  //onActionComplete execute after change date from date picker
  onActionComplete(args): void {
    if ((args.requestType == "viewNavigate" || args.requestType == "dateNavigate")) {
      this.getShechdualForDay();
    }
  }
  onActionBegin(args: ActionEventArgs): void {

  }
  getRandomColor(): string {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor
  }

  createAppointmentForPatient() {
    var error = "";
    if (this.selectedServiceID == "-1") {
      error = "Please select service first.";
    }
    else if (this.selectedDate == null) {
      error = "Please select appointment time first.";
    }
    if (error) {
      this._snackBar.open(error, "Error", {
        duration: 4000,
      });
    }
    else {
      this.openNewPatientDialog();
    }
  }
  selectedPatient: any = null;
  openNewPatientDialog() {
    const dialogRef = this.dialog.open(CreatePatientComponent, {
      width: '320px',
      data: null
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result) {
        this.checkUserDOB(result);
      }
    });
  }
  private checkUserDOB(result: any) {
    var error = "";

    var se = this.listServices.find(x => x.id == this.selectedServiceID);


    error = this.csValidation.validateCondition(se.conditions,
      {
        age: result.dateOfBirth,
        gender: result.sex == 0 ? "male" : "female"
      });
    debugger;
    if (error != "") {
      error = error + "<br/>Do you want to create appointment?";
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          isRadio: false,
          message: error,
          buttonText: {
            ok: 'Yes',
            cancel: `No`
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {

        if (confirmed != undefined && confirmed == true) {
          this.continueDOBValidation(result);
        }
      });
    }
    else {
      this.continueDOBValidation(result);
    }


  }

  private continueDOBValidation(result: any) {
    this.selectedPatient = result;
    this.selectedAddresIndex = -2;
    this.newAppointment.patientID = result.id;
    this.newAppointment.phoneNumber = this.deSanitizeMobileNumber.transform(result.phoneNumber);
    this.newAppointment.address = {
      firstName: result.firstName,
      lastName: result.lastName,
    };
    this.userService.getUserAddresses(result.parentId ? result.parentId : result.id).subscribe(data => {
      this.addresses = data ? data.address : null;

    });
  }

  confirmForAutoNextDose() {
    /// code commented to bypass second dose creation



    // var se = this.listServices.find(x => x.id == this.selectedServiceID);
    // if (se.name.toLowerCase().indexOf('covid') > -1) {
    //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //     data: {
    //       isRadio: true,
    //       message: `Do you want auto booking for <b>second
    //       dose</b> after 112
    //   days for ` + this.addresses[this.selectedAddresIndex].firstName + " " + this.addresses[this.selectedAddresIndex].lastName + "?",
    //       buttonText: {
    //         ok: 'Yes, I want',
    //         cancel: `No, I don't want`
    //       }
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {

    //     if (confirmed != undefined) {
    //       this.confirmAndCreate(confirmed);
    //     }
    //   });
    // }
    // else {
    //   this.confirmAndCreate(false);
    // }
    if (this.listQuestionnaire && this.listQuestionnaire.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          isRadio: false,
          message: 'Do you want fill the questionnaire',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {

        if (confirmed != undefined && confirmed == true) {
          this.createQuetionareDialog();
        }
        else if (confirmed != undefined && confirmed == false) {
          this.confirmAndCreate(false, null);
        }
      });
    }
    else {
      this.confirmAndCreate(false, null);
    }
  }
  createQuetionareDialog() {
    this.listQuestionnaire.forEach(element => {
      element.answer = "";
    });
    const dialogRef = this.dialog.open(QuestionaireDialogComponent, {
      data: this.listQuestionnaire,
      width: '520px'
    });
    dialogRef.afterClosed().subscribe((data: [any]) => {

      if (data != undefined && data.length > 0) {
        this.confirmAndCreate(false, data);
      }
    });
  }
  confirmAndCreate(auto: boolean = false, questionAns: [any]) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        message: 'Do you want to create an appointment for ' + this.addresses[this.selectedAddresIndex].firstName + " " + this.addresses[this.selectedAddresIndex].lastName + "?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed != undefined && confirmed == true) {

        var dt = this.datePipe.transform(new Date(this.selectedDate.getTime() + (this.selectedDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
        var se = this.listServices.find(x => x.id == this.selectedServiceID);
        var app = { patientID: this.newAppointment.patientID, address: this.addresses[this.selectedAddresIndex], pharmacyID: GlobalConstants.selectedPharmacyID, serviceID: this.selectedServiceID, slotName: this.timeSlotName, timeslot: dt, phoneNumber: this.newAppointment.phoneNumber, note: this.newAppointment.note, timeslotStrClient: this.datePipe.transform(new Date(this.selectedDate.getTime()), "MM/dd/yyyy HH:mm"), dateOfBirth: this.selectedPatient.dateOfBirth } as Appointment;
        app.questionnaire = [];
        if (questionAns == null) {
          if (this.listQuestionnaire && this.listQuestionnaire.length > 0) {
            this.listQuestionnaire.forEach(element => {
              app.questionnaire.push({ questionId: element.id, answer: "" });
            });
          }
        }
        else {
          app.questionnaire = questionAns;
        }
        this.appointmentService.save(app, se.serviceType == 1? this.createCardiaObject(app):null, se.name.toLowerCase().indexOf('covid') > -1 && auto == true).subscribe(x => {
          this.newAppointment = null;
          if (x.data) {
            this.selectedData.patient = { patientName: this.addresses[this.selectedAddresIndex].firstName + " " + this.addresses[this.selectedAddresIndex].lastName };
            this._snackBar.open("Appointment created.", "OK", {
              duration: 2000,
            });
            setTimeout(() => {
              this.onWaitingAction.emit(x.data);
            }, 2000);
          } else {
            this._snackBar.open(x.errorMessage, "Error", {
              duration: 2000,
            });
          }
          setTimeout(() => {
            this.getShechdualForDay();
          });
        }, err => {
          this._snackBar.open("Something went wrong try again.", "OK", {
            duration: 2000,
          });
        });

      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }

      $('.ssld').removeClass('ssld');
      this.selectedDate = null;
    });
  }
  createCardiaObject(app: Appointment):any {
    var dob = this.selectedPatient.dateOfBirth;
    if (!dob) {
      dob = "01/01/1980";
    }
    //age: this.selectedPatient.dateOfBirth,
    var passport_no = this.listQuestionnaire.find(x => x.question.toLowerCase().indexOf("passport number") > -1).answer;
    var nationality = this.listQuestionnaire.find(x => x.question.toLowerCase().indexOf("nationality") > -1).answer;
    var data = {
      "basicInfo": {
        "appointment_date": this.datePipe.transform(new Date(this.selectedDate.getTime() + (this.selectedDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'"), // "2021-09-22",
        "test_type": "031217a0-2ea3-11eb-81d1-314696e9bed3",
        "adult": "1",
        "children": "0",
        "booking_time": this.datePipe.transform(new Date(this.selectedDate.getTime()),"hh:mm"),
      },
      "userInfo": {
        "adult": [
          {
            "first_name":app.address.firstName,
            "last_name": app.address.lastName,
            "gender": this.selectedPatient.sex == 0 ? 'male' : 'female',
            "date": dob.split('/')[1],
            "month": dob.split('/')[0],
            "year": dob.split('/')[2],
            "address": app.address.streetAddress,
            "province": "",
            "email": this.selectedPatient.email ? this.selectedPatient.email:"info@mylocalrx.ca",
            "phone": app.phoneNumber,
            "phn": null,
            "nationality": nationality,
            "passport_no": passport_no,
            "test_reason": "",
            "test_reason_type": ""
          }
        ],
        "child": [

        ]
      },
      "billing": {
        "first_name": app.address.firstName,
        "last_name": app.address.lastName,
        "address": app.address.streetAddress,
        "city": app.address.city,
        "province": "",
        "postal_code": app.address.postalCode ? app.address.postalCode:"2",//this.data.address2.postalCode,
        "phone": app.phoneNumber,
        "email": this.selectedPatient.email ? this.selectedPatient.email :"info@mylocalrx.ca",
        "payment_type": "cash",
        "amount": 10,

      }
    };

    return data;
  }
  currentP: any = null;
  getPatietById(id: any) {
    $('.csLoader').css({ display: 'flex' });
    this.userService.getPatientById(id).subscribe(x => {
      this.currentP = x;
      console.log("patient=",this.currentP,x)
      $('.csLoader').css({ display: 'none' });
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }
  closePatient() {
    this.currentP = null;
  }
  userAddress(element: Address): string {
    var formatedA = [];
    if (element.unitNo) {
      formatedA.push(element.unitNo);
    }
    if (element.buildingName) {
      formatedA.push(element.buildingName);
    }
    formatedA.push(element.streetAddress + ", " + element.city + " " + element.postalCode);
    element.streetAddress = formatedA.join(", ");
    return formatedA.join(", ");
  }
  showQuickInfo = true;
  onPopupOpen(args: PopupOpenEventArgs): void {
    // console.log(args)
    if (args.type === 'QuickInfo') {
      // console.log(args.target.classList.contains('e-work-cells'),'-',args.target.classList.contains('e-header-cells'))
      if (args.target.classList.contains('e-work-cells') || args.target.classList.contains('e-header-cells')) {
        // this.scheduleObj.quickPopup.quickPopupHide(true);
        this.scheduleObj.closeQuickInfoPopup();
        args.cancel = true;
      } else if (args.target.classList.contains('e-appointment')) {
        (<HTMLElement>args.element).style.boxShadow = `1px 2px 5px 0 ${(<HTMLElement>args.target).style.backgroundColor}`;
        args.cancel = false;
      }
    }
    if (args.type === 'Editor' && args.name == "popupOpen") {
      var se = this.listServices.find(x => x.id == args.data.serviceId);
// console.log("editor=",se.minorAilment_type);
      var startDate = this.localDateTimePipe.transform(se.startDate);
      var endDate = this.localDateTimePipe.transform(se.endDate);
      var dateRangeError = "";
      if (args.data.StartTime.getTime() < startDate.getTime()) {
        dateRangeError = `Appointment booking for ${se.name} will start from ${this.datePipe.transform(startDate, "MMM/dd/yyyy")}`;
      }
      else if (args.data.StartTime.getTime() > endDate.getTime()) {
        dateRangeError = `Appointment booking will not accept for ${se.name} after ${this.datePipe.transform(endDate, "MMM/dd/yyyy")}`;
      }

      args.cancel = true;
      if (dateRangeError) {
        this._snackBar.open(dateRangeError, "Error", {
          duration: 4000,
        });
      }
      else {
        this.selectedServiceID = "";
        this.timeSlotName = "";
        this.selectedDate = null;
        this.selectedServiceID = args.data.serviceId;
        var se = this.listServices.find(x => x.id == this.selectedServiceID);
        // console.log('popupse =',se)
        var day = se.daysOfTheWeek.find(x => x.day == args.data.StartTime.getDay());
        if (day.active) {

          this.newAppointment = {
            id: "",
            date: args.data.StartTime,
            slots: day.timeSlots,
            patientID: "",
            address: null,
            phoneNumber: "",
            note: "",
          };
          var questionsId = this.getQuetionaireId(se);

          this.appointmentService.getQuestionnaire(questionsId).subscribe(quest => {
            this.listQuestionnaire = quest;
          });
          setTimeout(() => {
            this.serviceChanged();
            this.openNewPatientDialog();
          }, 100);
        }
        else {
          dateRangeError = `Appointment is not available for ${this.datePipe.transform(args.data.StartTime, "MMM/dd/yyyy")}`;
          this._snackBar.open(dateRangeError, "Error", {
            duration: 4000,
          });
        }
      }
    }

  }
  private getQuetionaireId(se: any):string {
    var id = se.serviceId;
    switch (se.serviceType) {
      case 0: id = "covid"; break;
      case 1: id = "covid_asymptomatic_test"; break;
      case 4: id = "covid_symptomatic_test"; break;
    }
    return id;
   // return se.serviceType == 0 ? "covid" : (se.serviceType == 1 ? "covid_asymptomatic_test" : se.serviceId);
  }

  reschedule(data) {
    // console.log("reshedule=",data);
    var reData = this.listBooked.find(x => x.id == data.id);
    // console.log(reData)
    this.newAppointment = reData;
    this.addresses = [];
    this.addresses.push(this.newAppointment.address);
    this.selectedAddresIndex = 0;
    this.selectedServiceID = reData.serviceID;

    var se = this.listServices.find(x => x.id == reData.serviceID);
    var day = se.daysOfTheWeek.find(x => x.day == data.startTime.getDay());
    this.newAppointment.date = data.startTime;
    this.newAppointment.slots = day.timeSlots;
    this.newAppointment.questionnaire = data.questionnaire;
    this.timeSlotName = reData.slotName;
    setTimeout(() => {
      this.timeSlotChange();
    }, 100);
  }
  bookAppointmentForWaitingLits() {
    // console.log("inp=",this.inp_patient.phoneNumber,this.inp_patient.id,this.inp_patient.dateOfBirth)
    this.newAppointment = this.newAppointment = {
      id: "",
      date: new Date(),
      slots: null,
      patientID: this.inp_patient.id,
      address: null,
      phoneNumber: this.inp_patient.phoneNumber,
      dateOfBirth: this.inp_patient.dateOfBirth,
      pharmacyID: this.inp_pharmacyID,
      serviceID: this.inp_serviceID,
      note: "",
    }
    this.selectedServiceID = this.inp_serviceID;

    var se = this.listServices.find(x => x.id == this.newAppointment.serviceID);
    var day = null;

    for (var i = 0; i < 7; i++) {
      if (day == null) {
        this.newAppointment.date.setDate(this.newAppointment.date.getDate() + 1)
        day = se.daysOfTheWeek.find(x => x.day == this.newAppointment.date.getDay());
        if (!day.active) {
          day = null;
        }
      }
    }

    this.timeSlotName = "";
    if (day != null) {

      this.newAppointment.slots = day.timeSlots;
      // console.log("newslot=",this.newAppointment.slots)

      this.timeSlotName = day.timeSlots[0].timeSlotName;
      // console.log("timeSlotName=",this.timeSlotName)
    }
    this.selectedPatient = this.inp_patient;
    // console.log("selectedPatient=",this.selectedPatient)
    this.userService.getUserAddresses(this.inp_patient.parentId ? this.inp_patient.parentId : this.inp_patient.id).subscribe(data => {
      this.newAppointment.address = {
        firstName: this.inp_patient.firstName,
        lastName: this.inp_patient.lastName,
      };
      this.addresses = data ? data.address : null;
      // if(this.addAddress){
      //   this.selectedAddresIndex = 0;
      // }
      // else{
      //   this.selectedAddresIndex = -1;
      // }
      setTimeout(() => {
        this.timeSlotChange();
      }, 100);

    });

  }
  updateAppointment() {
    var dt = this.datePipe.transform(new Date(this.selectedDate.getTime() + (this.selectedDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    var se = this.listServices.find(x => x.id == this.selectedServiceID);
    // console.log("se",se)
    var app = { id: this.newAppointment.id, patientID: this.newAppointment.patientID, address: this.addresses[this.selectedAddresIndex], pharmacyID: this.newAppointment.pharmacyID, serviceID: this.selectedServiceID, slotName: this.timeSlotName, timeslot: dt, phoneNumber: this.newAppointment.phoneNumber, note: this.newAppointment.note, timeslotStrClient: this.datePipe.transform(new Date(this.selectedDate.getTime()), "MM/dd/yyyy HH:mm"), dateOfBirth: this.newAppointment.dateOfBirth } as Appointment;
    this.appointmentService.updateAppointment(app).subscribe(x => {
      this.newAppointment = null;
      if (x.id) {
        this.selectedData.patient = { patientName: this.addresses[this.selectedAddresIndex].firstName + " " + this.addresses[this.selectedAddresIndex].lastName };
        this._snackBar.open("Appointment re-scheduled.", "OK", {
          duration: 2000,
        });
      } else {
        this._snackBar.open(x.errorMessage, "Error", {
          duration: 2000,
        });
      }
      setTimeout(() => {
        this.getShechdualForDay();
      });
    }, err => {
      this._snackBar.open("Something went wrong try again.", "OK", {
        duration: 2000,
      });
    });
  }
  newAppointment: any = null;
  serviceChanged() {
    this.selectedDate = null;

    this.selectedSlot = null;
  }
  selectedSlot = null;
  timeSlotChange(callOnDateChanged: boolean = false) {
    this.selectedDate = null;
    var se = this.listServices.find(x => x.id == this.selectedServiceID);
    var day = se.daysOfTheWeek.find(x => x.day == this.newAppointment.date.getDay());
    var slot = day.timeSlots.find(x => x.timeSlotName == this.timeSlotName);
    var startDate = this.localDateTimePipe.transform(se.startDate)
    startDate.setHours(0, 0, 0, 0);
    var endDate = this.localDateTimePipe.transform(se.endDate);
    endDate.setHours(23, 59, 59, 999);
    if (slot && day.active && (this.newAppointment.date.getTime() >= startDate.getTime() && this.newAppointment.date.getTime() <= endDate.getTime())) {
      var time = slot.startTime.split(":");
      var dt = this.newAppointment.date as Date;
      dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
      var running = true;
      slot.data = [{}];
      var currentDt = new Date();
      currentDt.setMinutes(currentDt.getMinutes() + 30);
      var preBooked = this.listBooked.filter(x => x.serviceID == this.selectedServiceID);
      debugger;
      while (running) {
        var isActive = se.daysOfTheWeek.find(dow => dow.day == dt.getDay()).active;

        if (currentDt.getTime() > dt.getTime()) {
          isActive = false;
        }
        var booked = preBooked.find(x =>
          this.datePipe.transform(this.localDateTimePipe.transform(x.timeslot), "MM/dd/yyyy HH:mm:ss") == this.datePipe.transform(dt, "MM/dd/yyyy HH:mm:ss") && x.status != 1);



        slot.data.push({ date: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds()), booked: booked ? true : false, isActive: isActive });
        dt.setMinutes(dt.getMinutes() + se.slotDuration!);
        var end = this.datePipe.transform(dt, "HH:mm:ss");
        running = !(parseInt(end!.replace(/:/g, '')) >= parseInt(slot.endTime.replace(/:/g, '')));
      }
      slot.data.splice(0, 1);
      this.selectedSlot = slot;
    }
    else {
      this.selectedSlot = null;
      var dateRangeError = `Appointment is not available for ${this.datePipe.transform(this.newAppointment.date, "MMM/dd/yyyy")}`;
      this._snackBar.open(dateRangeError, "Error", {
        duration: 4000,
      });
    }

  }
  selectedDate = null;
  selectedData = null;
  timeSlotName = "";
  dateSelected(target, data) {
    this.selectedData = data;
    $('.ssld').removeClass('ssld');
    this.selectedDate = null;
    if (!data.booked) {
      this.selectedDate = data.date;
      $(target).addClass('ssld');
    }
  }
  selectedAddresIndex = -2;
  AddressChanged() {
    if (this.selectedAddresIndex > -1) {
      this.addresses[this.selectedAddresIndex].firstName = this.selectedPatient.firstName;
      this.addresses[this.selectedAddresIndex].lastName = this.selectedPatient.lastName;
    }
  }
  close() {
    this.newAppointment = null;
    if (this.inp_patient) {
      this.onWaitingAction.emit(null);
    }
  }
  closeAddress() {
    this.selectedAddresIndex = -2;
  }
  addAddress(addressForm: NgForm) {

    if (addressForm.valid) {
      if (this.addresses == null || this.addAddress == undefined) {
        this.addresses = [];
      }
      this.addresses.push(JSON.parse(JSON.stringify(this.newAppointment.address)));

      setTimeout(() => {
        this.selectedAddresIndex = this.addresses.length - 1;
      }, 100);
    } else {
      var firstInvalid = false;
      for (const field in addressForm.form.controls) {
        const control = addressForm.form.get(field);
        if (control.invalid && !firstInvalid) {
          control.markAsTouched();
          $('#' + field).focus();

          firstInvalid = true;
        }
      }
    }
  }

  getBackGroundColor(data: { [key: string]: Object }) {
    if (data.serviceId) {
      return { 'background-color': this.listServices.find(x => x.id == data.serviceId).color };
    }
    return null;
  }
  refresPage() {
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

  cancelAppointment(data: any) {
    const dialogRef = this.dialog.open(SelectReasonComponent, {
      width: '450px',
      data: {
        message: `Do you want to cancel the
        appointment of <b>${data.patientName}</b>?`,
        listOptions: [{ id: 0, value: 'The patient did not attend the scheduled appointment.' }, { id: 1, value: 'Cancelled by the patient.' }, { id: 2, value: 'Cancellation due to vaccine unavailability.' }]
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {

      if (confirmed != undefined) {

        this.appointmentService.cancelBooking(data.id, confirmed).subscribe(x => {
          this._snackBar.open(data.patientName + "'s appointment canceled.", "OK", {
            duration: 2000,
          });

          setTimeout(() => {
            this.getShechdualForDay();
          });
        }, error => {
          this._snackBar.open("Some thing went wrong try again.", "OK", {
            duration: 2000,
          });
        });


      }
    });
  }

  printSchedule(): void {
    this.scheduleObj.print();
  }
  previewData = null;
  viewDetails(data: any) {
    debugger;
    // data.minorAilment_type = this.listBooked.find(x => x.id == data.id).minorAilment_type;
    if (data && data.updateCardia) {
      console.log('AppointmentId',data.id);
      localStorage.setItem("appointment_id",data.id)
      this.listBooked.find(x => x.id == data.id).cardiaId = data.cardiaId;
      this.listBooked.find(x => x.id == data.id).cardiaStatus = data.cardiaStatus;
    }
    else {
      if (data) {
        if (!data.cardiaId) {
          data.cardiaId = this.listBooked.find(x => x.id == data.id).cardiaId;
          // data.minorAilment_type = this.listBooked.find(x => x.id == data.id).minorAilment_type;
        }
        if (!data.cardiaStatus) {
          data.cardiaStatus = this.listBooked.find(x => x.id == data.id).cardiaStatus;
          // data.minorAilment_type = this.listBooked.find(x => x.id == data.id).minorAilment_type;
        }
        data.address2 = this.listBooked.find(x => x.id == data.id).address;
         data.minorAilment_type = this.listBooked.find(x => x.id == data.id).minorAilment_type;
         console.log('aa',data.minorAilment_type);
      }
      this.previewData = data;
    }

  }

  addressSelected(data) {
    this.zone.run(() => {

      this.newAppointment.address.unitNo = data.unitNo;
      this.newAppointment.address.postalCode = data.postalCode;
      this.newAppointment.address.city = data.city;
      this.newAppointment.address.streetAddress = data.streetAddress;
    });
  }



}
export interface Pharmacy {
  id: string;
  name: string;

}