import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Service } from 'src/app/shared/models/schedual.model';
import { LocalDateTimePipe } from 'src/app/shared/pipes/local-date-time.pipe';
import { ConfirmationDialogComponent } from 'src/app/shared/view/confirmation-dialog/confirmation-dialog.component';
import { AddEditConditionsComponent } from '../add-edit-conditions/add-edit-conditions.component';

@Component({
  selector: 'app-create-new-conf-dialog',
  templateUrl: './create-new-conf-dialog.component.html',
  styleUrls: ['./create-new-conf-dialog.component.css']
})
export class CreateNewConfDialogComponent implements OnInit {
  serviceForm: FormGroup
  constructor(public dialogRef: MatDialogRef<CreateNewConfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private _snackBar: MatSnackBar, private datePipe: DatePipe, private localDateTime: LocalDateTimePipe, public dialog: MatDialog) { }

  tempDaysOfTheWeek = [{ day: 0, active: true, timeSlots: [] }, { day: 1, active: true, timeSlots: [] }, { day: 2, active: true, timeSlots: [] }, { day: 3, active: true, timeSlots: [] }, { day: 4, active: true, timeSlots: [] }, { day: 5, active: true, timeSlots: [] }, { day: 6, active: true, timeSlots: [] }];
  tempServiceType = [{ id: -1, name: 'Select service type' },
  
  // { id: 8, name: "Bacterial or viral Eye infections" },
  { id: 6, name: "Cannabis Consultation" },
  // { id: 10, name: "Cold sores" },
  { id: 0, name: "Covid vaccination" },
  { id: 3, name: "Flu shot" },
  { id: 2, name: "General service" },
  { id: 7, name: "Minor Ailments" },
  { id: 8, name: "Physio" },
  { id: 9, name: "Spa" },
{id:1, name:"Asymptomatic-test"},
{id:4, name:"Symptomatic-test"},

  // { id: 12, name: "Heartburn" },
  // { id: 13, name: "Hemorrhoids" },
  // { id: 11, name: "Impetigo" },
  // { id: 9, name: "Insect bites and hives" },
  // { id: 17, name: "Menstrual cramps" },
  // { id: 18, name: "Musculoskeletal sprains and strains" },
  // { id: 16, name: "Oral thrush" },
  { id: 5, name: "Shingrix Service" },
  // { id: 15, name: "Skin rashes" },
  // { id: 14, name: "Tick bites" },
  // { id: 19, name: "Urinary tract infections" },
  
   
    ]
  service = {
    active: true,
    serviceName: "",
    startDate: null,
    endDate: null,
    slotDuration: -1,
    daysOfTheWeek: null,
    obsolete: false,
    serviceType: -1,
    serviceProvider: -1
  } as Service;

  slotDuration = [5, 10, 15, 20, 45, 60];
  timeSlote = [];
  oldData = null;
  tempServiceProvider = null;
  ngOnInit() {
    for (var i = 7; i < 22; i++) {
      for (var j = 0; j < 60; j += 5) {
        var h = i.toString();
        if (i < 10) {
          h = "0" + i.toString();
        }
        var m = j.toString();
        if (j < 10) {
          m = "0" + j.toString();
        }
        this.timeSlote.push(h + ":" + m + ":00");
      }
    }
    if (this.data) {
      this.service = JSON.parse(JSON.stringify(this.data)) as Service;
      console.log(this.service.startDate);
      this.service.startDate = this.localDateTime.transform(this.data.startDate);
      this.service.endDate = this.localDateTime.transform(this.data.endDate);
      console.log(this.service.startDate);
      this.serviceChange(this.service.serviceType);
    }
    else {
      this.service.startDate = new Date();
      var endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      endDate.setMonth(endDate.getMonth() + 1);
      this.service.endDate = endDate;
    }
    if (!this.service.daysOfTheWeek) {
      this.service.daysOfTheWeek = this.tempDaysOfTheWeek;

    }
    if (!this.service.slotDuration) {
      this.service.slotDuration = -1;
    }
    if (!this.service.conditions) {
      this.service.conditions = [];
    }
  }
  onConfirmClick(save: boolean) {
    if(this.service.serviceType == 7){
       this.service.serviceName = 'Minor Ailments'
    }
//     if(this.service.serviceType == 8){
//       this.service.serviceName = 'Physio'
//    }
//    if(this.service.serviceType == 9){
//     this.service.serviceName = 'Spa'
//  }

    var error = "";
    if (save) {
      var oneSlot = this.service.daysOfTheWeek.filter(x => x.timeSlots.length > 0);

      if (this.service.slotDuration <= 0) {
        error = "Please select slot duration.";
      }
      if (this.service.serviceType < 0) {
        error = "Please select service type";
      }
      if (this.service.serviceName.trim() == "") {
        error = "Please enter service name.";
      }

      if (!oneSlot || oneSlot.length == 0) {
        error = "Please create one slot atleast.";
      }
      this.service.serviceProvider = 0;
      // if (this.service.serviceType == 1 && this.service.serviceProvider <0) {
      //   error = "Please select service provider.";
      // }
      // else if (this.service.schedules.timeSlots.length <= 0) {

      //   error = "Please add time slot.";
      // }
      // else {
      //   this.service.schedules.timeSlots.forEach(tt => {
      //     if (!tt.startTime || !tt.endTime) {
      //       error = "Please select valid time";
      //     }
      //     else {
      //       var startTime = parseInt(tt.startTime.replace(/:/g, ''));
      //       var endTime = parseInt(tt.endTime.replace(/:/g, ''));
      //       if (startTime >= endTime) {
      //         error = "End time must be greater than start time.";
      //       }
      //     }
      //     if (!tt.timeSlotName) {
      //       error = "Please enter the slot name";
      //     }
      //   });
      // }
      if (error != "") {
        this._snackBar.open(error, "Error", {
          duration: 4000,
        });
      } else {
        this.service.startDate.setHours(0, 0, 0, 101);
        this.service.daysOfTheWeek.forEach(day => {
          day.timeSlots.forEach(slot => {
            slot.gmtStartTime = new Date(this.service.startDate.getTime());
            slot.gmtEndTime = new Date(this.service.startDate.getTime());
            var st = slot.startTime.split(":").map(a => { return parseInt(a) });
            slot.gmtStartTime.setHours(st[0], st[1], st[2], 101);
            var en = slot.endTime.split(":").map(a => { return parseInt(a) });
            slot.gmtEndTime.setHours(en[0], en[1], en[2], 999);
            slot.gmtStartTime = this.datePipe.transform(new Date(slot.gmtStartTime.getTime() + (slot.gmtStartTime.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
            slot.gmtEndTime = this.datePipe.transform(new Date(slot.gmtEndTime.getTime() + (slot.gmtEndTime.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
          });
        });
        debugger;
        this.service.startDate = this.datePipe.transform(new Date(this.service.startDate.getTime() + (this.service.startDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");

        this.service.endDate.setHours(23, 59, 59, 999);
        this.service.endDate = this.datePipe.transform(new Date(this.service.endDate.getTime() + (this.service.endDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
        this.dialogRef.close(this.service);
      }
    }
    else {
      this.dialogRef.close();
    }
  }

  currentSlot = null;
  showSlotEditor = false;
  slotIndex = 0;
  dayIndex = -1;
  addNewTimeSlot(item, slot, i, d) {
    if (!item.timeSlots) {
      item.timeSlots = [];
    }
    if (slot == null) {
      slot = { startTime: "07:00:00", endTime: "07:00:00", timeSlotName: "" };
    }
    this.currentSlot = slot;
    this.slotIndex = i;
    this.dayIndex = d;
    setTimeout(() => {
      this.showSlotEditor = true;
    }, 100);
  }

  SaveSlot() {
    var error = "";
    var startTime = parseInt(this.currentSlot.startTime.replace(/:/g, ''));
    var endTime = parseInt(this.currentSlot.endTime.replace(/:/g, ''));
    if (!this.currentSlot.timeSlotName) {
      error = "Please enter the slot name";
    }
    else if (startTime >= endTime) {
      error = "End time must be greater than start time.";
    }
    else if (startTime == endTime) {
      error = "End time and start time can't be equal.";
    }

    var duplicateSlotName = this.service.daysOfTheWeek[this.dayIndex].timeSlots.find(x => x.timeSlotName.toLowerCase() == this.currentSlot.timeSlotName.toLowerCase());
    if (duplicateSlotName) {
      error = `${this.currentSlot.timeSlotName} is already exist, try different name`;
    }

    if (error != "" && this.slotIndex == -1) {
      this._snackBar.open(error, "Error", {
        duration: 4000,
      });
      return;
    }
    if (this.slotIndex > -1) {

      this.service.daysOfTheWeek[this.dayIndex].timeSlots[this.slotIndex] = this.currentSlot;
    }
    else {
      this.service.daysOfTheWeek[this.dayIndex].timeSlots.push(this.currentSlot);
    }
    setTimeout(() => {
      this.showSlotEditor = false;
    }, 100);

    // if (this.service.daysOfTheWeek[this.dayIndex].timeSlots.length > 1) {
    //   this.service.daysOfTheWeek.forEach(element => {
    //     element.timeSlots = this.service.daysOfTheWeek[this.dayIndex].timeSlots;
    //   });
    // }
  }

  addNewCondition(isNew: boolean, index: number) {
    var data = { condition: null, index: null };
    data.condition = JSON.parse(JSON.stringify(this.service.conditions));
    if (isNew) {
      data.condition.push({ propertyName: "", condition: -1, isActive: true });
      data.index = data.condition.length - 1;
    }
    else {
      data.index = index;
    }


    const dialogRef = this.dialog.open(AddEditConditionsComponent, {
      width: '620px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.conditions = result.condition;
      }
      console.log(result);
    });
  }
  deleteCondition(propertyName: string, i: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        message: 'Do you want to delete <b>' + propertyName + "</b> condition?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed != undefined && confirmed == true) {
        this.service.conditions.splice(i, 1);
      }
    });
  }
  deleteService() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        message: 'Do you want to delete this service?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed != undefined && confirmed == true) {
        this.service.obsolete = true;
        this.dialogRef.close(this.service);
      }
    });
  }

  serviceChange(val: any) {
    // this.tempServiceProvider = this.tempServiceType.find(x => x.id == this.service.serviceType).provider;

  }

}
