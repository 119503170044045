import { Component, NgZone } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';

import { Pharmacy } from './shared/models/pharmacy.model';
import { GlobalConstants } from './shared/global-constants';

import { PharmacyService } from './service/pharmacy.service';
import { UserService } from './service/user.service';
import { Patient, User, UserRole } from './shared/models/user.model';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MessagingService } from './shared/messaging.service';
import { RoutLink } from './shared/models/rout.link.model';
import { SiteConfigService } from './service/site-config.service';
import { MatDialog } from '@angular/material';
import { OkOnlyComponent } from './shared/view/ok-only/ok-only.component';
import { ValidationUtil } from './shared/ValidationUtil';
import { ConfirmationDialogComponent } from './shared/view/confirmation-dialog/confirmation-dialog.component';
import { EanbleTwoFAComponent } from './shared/view/eanble-two-fa/eanble-two-fa.component';
import { LoginDialogComponent } from './shared/view/login-dialog/login-dialog.component';
declare var $: any
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  marquee = null;
  title = 'MyLocalRX Pharmacy Dashboard';

  navLinks: RoutLink[];
  activeLinkIndex = -1;
  pharmacies: Array<Pharmacy>;
  selectedPharmacy: Pharmacy | undefined;
  selectedPharmacyID: string = "";
  selectedPharmacyAddress: string = "";

  changePwdForm: FormGroup;
  constructor(public dialog: MatDialog, private router: Router, private pharmacyService: PharmacyService, private userService: UserService, private fb: FormBuilder, private messageing: MessagingService, private zone: NgZone, private siteConfig: SiteConfigService) {
    this.changePwdForm = fb.group({
      oldpassword: ['', [Validators.required, Validators.minLength(4)]],
      newpassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(8)]],
    })
  }
  listMessage = [];
  currentUser: User = null;
  actDashboard = "dashboard";
  ngOnInit(): void {
    // console.log("ph_prod");
    this.messageing.currentMessage.subscribe(payload => {
      if (payload && payload.notification) {
        this.zone.run(xx => {

          this.listMessage.push(payload.notification.body);
        });
        // $('.notitfication').append($(`<span>${}</span>`));
        // $('.notitfication').show();
        // setTimeout(() => {
        //     $('.notitfication').hide();
        // }, 5000);
      }
    });
    this.router.events.subscribe((res) => {
      if (this.navLinks) {
        this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link.indexOf(this.router.url) > -1));
      }
    });
    this.userService.logInSuccess.subscribe(x => {
      this.currentUser = x;
      if (x) {
        this.actDashboard = this.currentUser.role == 2 ? "ready-to-pickup" : "dashboard";
        this.messageing.requestPermission(this.currentUser.id).then(fcm => {
          if (fcm.token) {
            this.messageing.receiveMessage();

            this.userService.updateFCMToken(fcm.userId, fcm.deviceId, fcm.token).subscribe(u => {

              console.log(u);
              fcm.isSaved = true;
              this.messageing.saveFcmOjbect(fcm);
            }, error => {
              // console.log(error);
            });
          }
        });

        this.createRouteLink(this.currentUser);
        if (localStorage.getItem("inv_pwd_formate")) {
         var msg = localStorage.getItem("inv_pwd_formate") == "true"
          ?"Your password does not satisfy the current policy requirements, do you want to update?"
          :"Please change your temorary password, do you want to change?" 
          localStorage.removeItem("inv_pwd_formate")
          setTimeout(() => {

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              maxWidth: "420px",
              closeOnNavigation: false,
              disableClose: true,
              data: {
                isRadio: false,
                message: msg,
                buttonText: {
                  ok: 'Yes',
                  cancel: 'No'
                }
              }
            });

            dialogRef.afterClosed().subscribe((confirmed: any) => {

              if (confirmed != undefined && confirmed) {
                this.ChangePassword();
              }
            });
          }, 4000);
        }
      }
    });
    this.siteConfig.getSiteConfig().subscribe(x => {
      this.marquee = x.find(m => m.active && m.configType == 1);
    });
  }

  Logout() {
    var currentUserId = GlobalConstants.getcurrentUser().id;
    var deviceId = GlobalConstants.getDeviceId();
    this.userService.doLogout(currentUserId, deviceId).subscribe(x => {
      try {
        this.messageing.deletToken(this.messageing.getFcmObject().token);
      } catch (e) { }
      setTimeout(() => {
        GlobalConstants.clearCurrentUserSession();

        window.location.href = window.location.origin;
      }, 100);
    }, err => {
      GlobalConstants.clearCurrentUserSession();

      window.location.href = window.location.origin;
      
    });
  }

  onPharmacySelect(pharmacyID: string): void {

    // console.log('Pharmacy Selected : ' + this.selectedPharmacyID);
    GlobalConstants.selectedPharmacyID = this.selectedPharmacyID;
    // console.log(`Global Pharmacy ID set ` + GlobalConstants.selectedPharmacyID);
    this.refreshComponent();
    this.setEnabledModule();

  }
  appointmentModuleEnabled = false;
  prescriptionModuleEnabled = false;
  setEnabledModule() {
    console.log("onchange pharmacy",this.pharmacies,this.appointmentModuleEnabled,this.prescriptionModuleEnabled)
    if (this.pharmacies !== undefined && this.pharmacies.length > 0) {
      var ss = this.pharmacies.find(x => x.id == GlobalConstants.selectedPharmacyID);
      console.log("setId=",ss)
      this.appointmentModuleEnabled = ss.appointmentModuleEnabled;
      console.log("enable module = ",this.appointmentModuleEnabled,ss.prescriptionModuleEnabled)
      this.prescriptionModuleEnabled = ss.prescriptionModuleEnabled;
    }
    this.navLinks.filter(x => x.index == 1).map(x => x.enable = this.prescriptionModuleEnabled);
    this.navLinks.filter(x => x.index == 2 || x.index == 3).map(x => x.enable = this.appointmentModuleEnabled);

    // this.navLinks = this.navLinks.filter(x => x.enable == true);
  }

  refreshComponent(): void {
    // save current route first
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });

  }
  createRouteLink(currentUser: User) {
    this.navLinks = GlobalConstants.routLinks.filter(x => x.allowedRoles.includes(currentUser.role));

    this.pharmacyService.getAll().subscribe(data => {
      this.pharmacies = data;

      if (this.pharmacies !== undefined && this.pharmacies.length > 0) {
        localStorage.setItem("pharmacieList", JSON.stringify(this.pharmacies));
        this.selectedPharmacy = this.pharmacies[0];
        this.selectedPharmacyID = this.pharmacies[0].id;
        this.selectedPharmacyAddress = this.selectedPharmacy.name + '\n ' + this.selectedPharmacy.add1 + '\n ' + this.selectedPharmacy.pin;
        GlobalConstants.selectedPharmacyID = this.selectedPharmacyID;
        this.currentUser.allowedPharmacy = this.pharmacies.map(f => f.id);
        localStorage.setItem(GlobalConstants.CURRENT_USER, JSON.stringify(this.currentUser));
        this.refreshComponent();
        this.setEnabledModule();
      }

    });
  }

  ChangePassword() {
    $(".changePassword").toggleClass('changePasswordShow');
  }
  onSubmitPwd() {
    if (this.changePwdForm.valid) {
      if (this.changePwdForm.controls["newpassword"].value != this.changePwdForm.controls["confirmpassword"].value) {
        this.changePwdForm.controls["confirmpassword"].markAsTouched();
      }
      else {
        if (!ValidationUtil.isValidPassword(this.changePwdForm.controls["newpassword"].value)) {
          this.dialog.open(OkOnlyComponent, {
            width: "420px",
            data: {
              title: "Error",
              message: ValidationUtil.INVALID_PASSWORD_ERROR,

            }
          });
          return;
        }
        this.userService.updatePassword({ oldPassword: this.changePwdForm.controls["oldpassword"].value, newPassword: this.changePwdForm.controls["newpassword"].value }).subscribe(x => {
          console.log(x);
          if (x.errorMessage) {
            this.dialog.open(OkOnlyComponent, {
              width: "420px",
              data: {
                title: "Error",
                message: x.errorMessage,

              }
            });
          }
          else {
            $(".changePassword").removeClass('changePasswordShow');
            this.dialog.open(OkOnlyComponent, {
              width: "420px",
              data: {
                title: "Info",
                message: "Your password has been changed.",

              }
            });
          }
        });
      }
    }
  }
  RemoveMesage(i) {
    this.listMessage.splice(i, 1);
  }



  enable2Fa(call: boolean) {
    // if (call) {
    //   $('.csLoader').css({ display: 'flex' });
    //   this.userService.enbaleTwoFa(this.currentUser.username).subscribe(x => {
    //     $('.csLoader').css({ display: 'none' });
    //     $('#2faDiv').html(`<img src="data:image/JPEG;base64,${x.data}" style='width:100%'/>`);
    //     $(".enable2fa").toggleClass('changePasswordShow');
    //   }, err => {
    //     $('.csLoader').css({ display: 'none' });
    //   });
    // }
    // else {

    //   $(".enable2fa").toggleClass('changePasswordShow');
    // }

    if (this.currentUser.twoFa) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          isRadio: false,
          message: "2 factor authentication is already enabled in your account,<br/>do you want reset 2FA?",
          buttonText: {
            ok: 'Yes',
            cancel: `No`
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {

        if (confirmed != undefined && confirmed == true) {
          this.call2Fa();
        }
      });
    }
    else {
      this.call2Fa();
    }
  }
  call2Fa() {

    const dialogRef = this.dialog.open(EanbleTwoFAComponent, {
      width: '320px',
      data: null,
      disableClose: true
    });
  }
  loginOpne = false;
  OpenLogin() { 
    if (!this.loginOpne) {
      this.loginOpne = true;
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        data: null
      });
      dialogRef.afterClosed().subscribe((v: any) => {
        this.loginOpne = false;
       });
    }
  }

}
