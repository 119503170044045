import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'prescriptionStatus' })
export class PrescriptionStatusTransformPipe implements PipeTransform {
      transform(value: number): string {
            if (value == PrescriptionStatuses.NEW) {
                  return "Delivery Scheduled";
            } else if (value == PrescriptionStatuses.CANCELLED_BY_PATIENT) {
                  return "Delivery Cancelled by Patient"
            } else if (value == PrescriptionStatuses.ACCEPTED_BY_PHARMACY) {
                  return "Delivery Accepted by Pharmacy"
            } else if (value == PrescriptionStatuses.CANCELLED_BY_PHARMCY) {
                  return "Delivery Cancelled by Pharmacy"
            } else if (value == PrescriptionStatuses.DELIVERED) {
                  return "Delivered"
            } else if (value == PrescriptionStatuses.OUT_FOR_DELIVERY) {
                  return "Out For delivery"
            } else if (value == PrescriptionStatuses.PARTIAL_READY) {
                  return "Partial delivery is ready to be picked up"
            } else if (value == PrescriptionStatuses.PICKED_UP) {
                  return "Picked up from pharmacy"
            } else if (value == PrescriptionStatuses.READY_TO_PICKUP) {
                  return "Ready to be picked up from pharmacy"
            } else if (value == PrescriptionStatuses.READY_TO_DELIVER) {
                  return "Ready to be delivered"
            } else if (value == PrescriptionStatuses.PICKED_UP_FAILED) {
                  return "Pick up Failed"
            } else if (value == PrescriptionStatuses.DELIVERY_FAILED) {
                  return "Delivery Failed"
            }
            return "UNKNOWN STATUS";
      }
}

@Pipe({ name: 'prescription_custom_status' })
export class PrescriptionCustomStatusTransformPipe implements PipeTransform {
      transform(value: number): string {
            if (value == PrescriptionCustomStatuses.NEW) {
                  return "New"
            } else if (value == PrescriptionCustomStatuses.RECEIVED) {
                  return "Received"
            } else if (value == PrescriptionCustomStatuses.ACCEPTED) {
                  return "Accepted"
            } else if (value == PrescriptionCustomStatuses.PROCESSED) {
                  return "Processed"
            } else if (value == PrescriptionCustomStatuses.REJECTED) {
                  return "Rejected"
            } else if (value == PrescriptionCustomStatuses.IN_PROCESS) {
                  return "In Process"
            }
      
            return "UNKNOWN STATUS";
      }
}

@Pipe({ name: 'prescriptionStatusColor' })
export class PrescriptionStatusColorTransformPipe implements PipeTransform {
      transform(value: number): string {
            if (value == PrescriptionStatuses.NEW) {
                  return "#e91e63";
            } else if (value == PrescriptionStatuses.CANCELLED_BY_PATIENT) {
                  return "red"
            } else if (value == PrescriptionStatuses.ACCEPTED_BY_PHARMACY) {
                  return "#ff9800"
            } else if (value == PrescriptionStatuses.CANCELLED_BY_PHARMCY) {
                  return "crimson"
            } else if (value == PrescriptionStatuses.DELIVERED) {
                  return "green"
            } else if (value == PrescriptionStatuses.OUT_FOR_DELIVERY) {
                  return "#3f51b5"
            } else if (value == PrescriptionStatuses.PARTIAL_READY) {
                  return "gray"
            } else if (value == PrescriptionStatuses.PICKED_UP) {
                  return "green"
            } else if (value == PrescriptionStatuses.READY_TO_PICKUP) {
                  return "#4caf50"
            } else if (value == PrescriptionStatuses.READY_TO_DELIVER) {
                  return "greenyellow"
            }
            else if (value == PrescriptionStatuses.DELIVERY_FAILED) {
                  return "red"
            }
            return "#000000";
      }
}

@Pipe({ name: 'prescriptionDeliveryType' })
export class PrescriptionDeliveryTypePipe implements PipeTransform {
      transform(value: number): string {
            if (value == PrescriptionType.SELF_PICKUP) {
                  return "Self Pickup";
            } else if (value == PrescriptionType.HOME_DELIVERY) {
                  return "Home Delivery";
            }
            else if (value == PrescriptionType.EXPRESS_DELIVERY) {
                  return "Express Delivery";
            }
            else if (value == PrescriptionType.OTC) {
                  return "OTC";
            }
            else if (value == PrescriptionType.CUSTOM_STOP) {
                  return "Custom stop created by driver";
            }
            return "UNKNOWN TYPE";
      }
}

@Pipe({ name: 'gender' })
export class GenderPipe implements PipeTransform {
      transform(value: number): string {
            if (value == Sex.MALE) {
                  return "MALE";
            } else if (value == Sex.FEMALE) {
                  return "FEMALE";
            }
            return "UNKNOWN TYPE";
      }
}
@Pipe({
      name: 'orderPaymentStatus'
})
export class OrderPaymentStatusPipe implements PipeTransform {

      transform(value: number, ...args: unknown[]): string {
            var status = "UN-KNOWN";
            switch (value) {
                  case OrderPaymentStatus.PENDING: status = "Pending"; break;
                  case OrderPaymentStatus.PROCESSING: status = "Processing"; break;
                  case OrderPaymentStatus.PAID: status = "Paid"; break;
                  case OrderPaymentStatus.FAIL: status = "Failed"; break;
                  case OrderPaymentStatus.REQUIREAUTH: status = "REQUIREAUTH"; break;
                  case OrderPaymentStatus.PARTIALY_REFUNDE: status = "PARTIALY_REFUNDE"; break;
                  case OrderPaymentStatus.FULL_REFUNDED: status = "FULL_REFUNDED"; break;
            }

            return status;
      }

}

export enum OrderPaymentStatus {
      PENDING, PROCESSING, PAID, FAIL, REQUIREAUTH, PARTIALY_REFUNDE, FULL_REFUNDED
}

export enum PrescriptionStatuses {
      NEW,
      CANCELLED_BY_PATIENT,
      CANCELLED_BY_PHARMCY,
      ACCEPTED_BY_PHARMACY,
      READY_TO_PICKUP,
      PARTIAL_READY,
      READY_TO_DELIVER,
      OUT_FOR_DELIVERY,
      DELIVERED,
      PICKED_UP,
      DELIVERY_FAILED,
      PICKED_UP_FAILED
}

export enum PrescriptionCustomStatuses {
      NEW,
      RECEIVED,
      ACCEPTED,
      REJECTED,
      IN_PROCESS,
      PROCESSED
}

export enum PrescriptionType {
      SELF_PICKUP,
      HOME_DELIVERY,
      EXPRESS_DELIVERY,
      OTC,
      CUSTOM_STOP
}

export enum Sex {
      MALE,
      FEMALE
}