import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-add-edit-conditions',
  templateUrl: './add-edit-conditions.component.html',
  styleUrls: ['./add-edit-conditions.component.css']
})
export class AddEditConditionsComponent implements OnInit {

  properties = [{ id: 'age', value: 'Patient age', valueType: "number", allowed: [], allowedContion: [0, 1, 2, 3, 4] },
  { id: 'gender', value: 'Patient gender', valueType: "string", allowed: ["male", "female"], allowedContion: [0] }];
  tmpListConditionTypes = [{ id: 0, value: 'EQUALS_TO' }, { id: 1, value: 'GREATER_THAN' }, { id: 2, value: 'GREATER_THAN_EQUALS_TO' }, { id: 3, value: 'LESS_THAN' }, { id: 4, value: 'LESS_THAN_EQUALS_TO' }];
  condition: any;
  index: any;
  constructor(public dialogRef: MatDialogRef<AddEditConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
    this.condition = data.condition;
    this.index = data.index;
  }

  ngOnInit() {

    var allowed = this.properties.find(x => x.id == this.condition[this.index].propertyName);
    this.listConditionTypes = this.tmpListConditionTypes.filter(x => allowed.allowedContion.indexOf(x.id) > -1);
  }
  listConditionTypes = [];
  propertyChanged() {
    this.condition[this.index].condition = -1
    this.condition[this.index].propertyValueMustBe = "";
    this.condition[this.index].errorMessage = "";
    var allowed = this.properties.find(x => x.id == this.condition[this.index].propertyName);
    this.listConditionTypes = this.tmpListConditionTypes.filter(x => allowed.allowedContion.indexOf(x.id) > -1);
  }
  onConfirmClick(confirm: boolean) {
    if (confirm) {
      var error = "";
      if (!this.condition[this.index].propertyName) {
        error = "Select property";
      }
      if (!error && this.condition[this.index].condition == -1) {
        error = "Select condition";
      }
      if (!error && !this.condition[this.index].propertyValueMustBe) {
        error = "Enter value";
      } else {
        var userInput = this.condition[this.index].propertyValueMustBe;
                
        var allowed = this.properties.find(x => x.id == this.condition[this.index].propertyName);
        if (allowed.valueType == "number" && parseInt(userInput).toString().length != userInput.toString().length) {
          error = "Value must be a number";
        }
        else if (allowed.valueType == "string" && allowed.allowed.length > 0 && allowed.allowed.indexOf(this.condition[this.index].propertyValueMustBe.toLowerCase()) == -1) {
          error = "Value must be " + allowed.allowed.join(", ");
        }
      }
      if (!error && !this.condition[this.index].errorMessage) {
        error = "Enter error message";
      }
      if (!error) {
        this.dialogRef.close(this.data);
      }
      else {
        this._snackBar.open(error, "Error", {
          duration: 4000,
        });
      }
    }
    else {
      this.dialogRef.close(null);
    }
  }

}
