import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: number): string {
    let rtn = "undefiend";
    switch (value) {
      case 0: rtn = "Admin"; break;
      case 1: rtn = "User"; break;
      case 2: rtn = "Delivery"; break;
      default: break;
    }
    return rtn;
  }

}
