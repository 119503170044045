import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'teams-delivery-header',
  templateUrl: './teams-delivery-header.component.html',
  styleUrls: ['./teams-delivery-header.component.css']
})
export class TeamsDeliveryHeaderComponent implements OnInit {

  currentUser: any;
  isLoggedIn: boolean = false;
  marquee: any = null;
  constructor() { }

  ngOnInit() {
  }

}
