import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { GlobalConstants } from '../shared/global-constants';
import { Patient, User, UserRole } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MyLocalRxUserService {

  constructor(private http: HttpClient) { }
    
    logInSuccess = new BehaviorSubject<User>(GlobalConstants.getcurrentUser()!);
    
    getUsersByPharmacy(id: string, search: string, pageIndex: number, pageSize: number): Observable<any> {
        let result: Observable<any>;
        result = this.http.get(environment.baseUrl + "/user/search/pagination/" + id + "?search=" + search + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize);
        return result;
    }

    addNewPatient(data: any): Observable<any> {
        data.createdByRole = UserRole.ADMIN;
        let result: Observable<any>;    
        result = this.http.post(environment.baseUrl + "/userwofile", data);
        return result;
    }
    
    getUserByEmail(email: string): Observable<User> {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/userByEmail/${email}`);
        return result;
    }
    searchUser(query: string): Observable<User[]> {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/user/search?search=${query}`);
        return result;
    }
    
    getUserAddresses(id: any) {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/user/${id}/Address`);

        return result;
    }
    getPatientById(id: any) {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/user/${id}`);

        return result;
    }
}
