import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-select-reason',
  templateUrl: './select-reason.component.html',
  styleUrls: ['./select-reason.component.css']
})
export class SelectReasonComponent implements OnInit {

  objData: SelectReason = null
  reason = -1;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SelectReason,
    private dialogRef: MatDialogRef<SelectReasonComponent>) {
    this.objData = data;
  }

  ngOnInit() {
  }
  onConfirmClick(val: boolean): void {
    if (val) {
      if (this.reason > -1 && this.reason.toString() != "-1") {

        this.dialogRef.close(this.reason);
      }
      else {
        this.dialogRef.close(undefined);
      }
    }

  }
}

export interface SelectReason {
  message: string;
  listOptions: any[];
}
