import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PharmacyStatsService {

  url = environment.baseUrl + "/prescription/stats/";
  constructor(private http: HttpClient) {

  }
  getMonthWiseChartData(pharmacyID: string): Observable<[StatesDTO]> {
    let result = new Observable<any>();
    result = this.http.get(this.url + "monthwise/" + pharmacyID);
    return result;
  }

  getDriverDeliveryData(driverId: string): Observable<[StatesDTO]> {
    let result = new Observable<any>();
    result = this.http.get(this.url + "driver/monthwise/" + driverId);
    return result;
  }
}
export class StatesDTO {
  _id: StatesDetailDTO;
  count: number
}
export class StatesDetailDTO {
  key: string;
  key_object: any;
  year: number;
  month: number;
  sort:Date
}
