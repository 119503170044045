import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, map } from 'rxjs/operators';
import { PharmacyStatsService, StatesDTO } from 'src/app/service/pharmacy-stats.service';
import { PrescriptionService } from 'src/app/service/prescription.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Address, Prescription } from 'src/app/shared/models/prescription.model';
import { PrescriptionDeliveryTypePipe, PrescriptionStatusTransformPipe } from 'src/app/shared/pipes/prescription-status.pipe';
import * as CanvasJS from '../../../assets/js/canvasjs.min';
declare var $: any;
declare var Chart: any;
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
	encapsulation:ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
	pharmacyData: StatesDTO[] = [];
	prescriptionsData:any;
	constructor(private statsService: PharmacyStatsService, private prescriptionService: PrescriptionService, private zone: NgZone) {
		
	}
	ngOnInit() {
		if (GlobalConstants.selectedPharmacyID) {
			this.statsService.getMonthWiseChartData(GlobalConstants.selectedPharmacyID).subscribe(x => {
				this.pharmacyData = x;
				this.iniPharmacy();
			});

			this.prescriptionService.getLastTenByPharmacy(GlobalConstants.selectedPharmacyID).subscribe(x => {
				this.prescriptionsData = x;

				this.prescriptionsData.data.forEach(element => {
					element.intial = element.deliveryAddress.firstName.charAt(0).toUpperCase() + element.deliveryAddress.lastName.charAt(0).toUpperCase();
					element.address = this.userAddress(element.deliveryAddress);
				});
			});
		}
	}
	
	userAddress(element: Address): string {
		var formatedA = [];
		if (element.unitNo) {
			formatedA.push(element.unitNo);
		}
		if (element.buildingName) {
			formatedA.push(element.buildingName);
		}
		formatedA.push(element.streetAddress + ", " + element.city + " " + element.postalCode);
		element.streetAddress = formatedA.join(", ");
		return formatedA.join(", ");
	}
	iniPharmacy() {
		var that = this;
		$(document).ready(function () {
			that.zone.run(() => {
				that.renderPharmacy();
			});
		});
	}
	monthsName = [
		"",
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		"July",
		"August",
		"September",
		"October", "November", "December"
	];
	backgroundColor= [
		'rgba(255, 99, 132, 0.2)',
		'rgba(255, 159, 64, 0.2)',
		'rgba(255, 205, 86, 0.2)',
		'rgba(75, 192, 192, 0.2)',
		'rgba(54, 162, 235, 0.2)',
		'rgba(153, 102, 255, 0.2)',
		'rgba(201, 203, 207, 0.2)',
		'rgba(255, 99, 132, 0.2)',
		'rgba(255, 159, 64, 0.2)',
		'rgba(255, 205, 86, 0.2)',
		'rgba(75, 192, 192, 0.2)',
		'rgba(54, 162, 235, 0.2)',
	];
	
	borderColor= [
		'rgb(255, 99, 132)',
		'rgb(255, 159, 64)',
		'rgb(255, 205, 86)',
		'rgb(75, 192, 192)',
		'rgb(54, 162, 235)',
		'rgb(153, 102, 255)',
		'rgb(201, 203, 207)',
		'rgb(255, 99, 132)',
		'rgb(255, 159, 64)',
		'rgb(255, 205, 86)',
		'rgb(75, 192, 192)',
		'rgb(54, 162, 235)',
	];
	renderPharmacy() {

		var temp = this.pharmacyData;

		var labels = [];
		var data = [];
		temp.forEach((element:any) => {
			element._id.sort =  new Date(element._id.year,element._id.month,1);
		});
		temp = temp.sort((a, b) => { return a._id.sort.getTime() - b._id.sort.getTime() });
		var max = 0;
		temp.forEach(element => {
			labels.push(this.monthsName[element._id.month]);
			data.push(element.count);
			if (element.count > max) {
				max = element.count;
			}
		});
		// const data = {
		//   labels: labels,
		//   datasets: [{
		//     label: 'Month wise prescription',
		//     backgroundColor: 'rgb(255, 99, 132)',
		//     borderColor: 'rgb(255, 99, 132)',
		//     data: [0, 10, 5, 2, 20, 30, 45],
		//   }]
		// };
		Chart.defaults.plugins.subtitle.display = false;
		Chart.defaults.plugins.subtitle.align = 'start';
		const config = {
			options: {
				animations: {
					tension: {
						duration: 1000,
						easing: 'easeInCirc',
						from: 1,
						to: 0,
						loop: true
					}
				},
				scales: {
					y: {
						beginAtZero: true
					}
				}
			},
			data: {

				labels: labels,
				datasets: [{
					type: 'bar',
					label: '',
					backgroundColor: this.backgroundColor,
					borderColor: this.borderColor,
					borderWidth: 1,
					data: data
				}, {
					type: 'line',
					label: '',
					data: data,
					fill: true,
					borderColor: 'rgb(197 191 228)',
					tension: 0.4,
					
				}]
			}
		};
		var myChart = new Chart(
			document.getElementById('phData'),
			config
		);
	}
	// constructor(private prescriptionService: PrescriptionService, private router: Router, private datePipe: DatePipe,private pDelType:PrescriptionDeliveryTypePipe) { }
	// prescriptions: Array<Prescription>;
	// ngOnInit() {
	// 	// this.router.events.subscribe(x => {
	// 	// 	if (x instanceof NavigationEnd) {
				
	// 	// 	}
	// 	// });

	// 	this.prescriptionService.getByPharmacyID(GlobalConstants.selectedPharmacyID).subscribe(data => {
	// 		let dataPoint = [];
	// 		let dataPointForPie = [];
	// 		this.prescriptions = data.data;
	// 		this.prescriptions.forEach(element => {
	// 			let prescriptionType =this.pDelType.transform(element.prescriptionType);
	// 			let month = this.datePipe.transform(new Date(element.uploadDateAndTime), "MMM");

	// 			if (dataPoint.find(x => x.label === month)) {
	// 				dataPoint.find(x => x.label === month).y++;
	// 			}
	// 			else {
	// 				dataPoint.push({ label: month, y: 1 });
	// 			}
	// 			if (dataPointForPie.find(x => x.label === prescriptionType)) {
	// 				dataPointForPie.find(x => x.label === prescriptionType).y++;
	// 			}
	// 			else {
	// 				dataPointForPie.push({ label: prescriptionType, y: 1 });
	// 			}
	// 		});

	// 		var that = this;
	// 		$(document).ready(function(){
	// 			that.RenderMonthlyChart(dataPoint);
	// 			that.RenderPieChart(dataPointForPie);
	// 		});
			
	// 	});
		
	// }

	// RenderMonthlyChart(dataPoints:any){
	// 	let chart = new CanvasJS.Chart("chartContainer", {
	// 		animationEnabled: true,
	// 		exportEnabled: true,
	// 		title: {
	// 			text: "Monthly Prescriptions Upload"
	// 		},
	// 		data: [{
	// 			type: "column",
	// 			dataPoints: dataPoints
	// 		}]
	// 	});

	// 	chart.render();
		
	// }
	// RenderPieChart(dataPoints:any){
	// 	let chart = new CanvasJS.Chart("pieContainer", {
	// 		animationEnabled: true,
	// 		exportEnabled: true,
	// 		title: {
	// 			text: "Prescription Type Upload"
	// 		},
	// 		data: [{
	// 			type: "pie",
	// 			dataPoints: dataPoints
	// 		}]
	// 	});

	// 	chart.render();
	// }

}
