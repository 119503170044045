import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css']
})
export class HomeContentComponent implements OnInit {

  constructor() { }

  queryForm = {
    name: "",
    email: "",
    mobileNum: "",
    message: ""
  }
  thankYouUser = "";
  ngOnInit() {
  }

}
