export interface Patient {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    password: any;
    email: any;
    phoneNumber: any;
   
    age: number;
    sex: number;
  }
  export enum UserRole{
    ADMIN,
    USER,
    DELIVERY
  }

  export interface User{
    id:string,
    username:string,
    firstName: string;
    lastName: string;
    password: any;
    token: string;
    role:UserRole
    allowedPharmacy:string[];
    ownerId:string;
    createdByRole:UserRole;
    dateOfBirth:any;
    phoneNumber: any;
    sex: number
    twoFa:boolean
  }