import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDayName'
})
export class ToDayNamePipe implements PipeTransform {

  transform(value: number): string {
    var rtn = "UNK";
    switch (value) {
      case 0: rtn = "SUN"; break;
      case 1: rtn = "MON"; break;
      case 2: rtn = "TUE"; break;
      case 3: rtn = "WED"; break;
      case 4: rtn = "THU"; break;
      case 5: rtn = "FRI"; break;
      case 6: rtn = "SAT"; break;
    }

    return rtn;
  }

}
