

import {Component, ViewChild,OnInit, AfterViewInit} from '@angular/core';
import { UserService } from '../service/user.service';
import {HttpClient} from '@angular/common/http';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { products } from './product';
import {MatPaginator, MatSort} from '@angular/material';
declare var $: any;
@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})
export class MyOrderComponent {
 
  // displayedColumns: string[] = ['created', 'state', 'number', 'title'];
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  // @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['sr','img','name','price', 'obutton'];
  dataSourceWithPageSize = new MatTableDataSource(products);
  constructor( public userservice:UserService) { }
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  // @ViewChild('paginator') paginator: MatPaginator;
   @ViewChild('paginatorPageSize', {static: true}) paginatorPageSize: MatPaginator;

  pageSizes = [10, 15, 20];

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceWithPageSize.paginator = this.paginatorPageSize;
  }

  ngOnInit() {

    this.dataSource = new MatTableDataSource(products);

    this.userservice.getAllgreenicheProducts().subscribe({
      next: data => {
        // console.log("DATA=",data)
    
  }
})




//   openit() {
//     // the url,html tag should be called from here , how ?
//     const URL = 'https://greeniche.wize.health/';
//     window.open(URL);
//   }
 }
}



// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-my-order',
//   templateUrl: './my-order.component.html',
//   styleUrls: ['./my-order.component.css']
// })
// export class MyOrderComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

//   openit() {
//     // the url,html tag should be called from here , how ?
//     const URL = 'https://greeniche.wize.health/';
//     window.open(URL);
//   }
// }
