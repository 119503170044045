import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { GlobalConstants } from '../shared/global-constants';
import { Pharmacy } from '../shared/models/pharmacy.model';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  public PHARMACY_API = environment.baseUrl + '/pharmacy';
  constructor(private http: HttpClient) { }

  getAll(all: boolean = false): Observable<Pharmacy[]> {
    let result: Observable<any>;
    if (all) {

      result = this.http.get(`${this.PHARMACY_API}`);
    }
    else {
      result = this.http.get(`${this.PHARMACY_API}?userid=${GlobalConstants.getcurrentUser().id}`);
    }
    return result;
  }

  getAllVehicles(): Observable<any> {
    return this.http.get(environment.baseUrl + "/vehicle");
  }

  getESTCurrentTime(id: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.get(this.PHARMACY_API + "/time/" + id);
    return result;
  }

  checkIsCardiaEnabled(id: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.get(this.PHARMACY_API + "/" + id +"/isCardiaEnabled");
    return result;
  }
}
