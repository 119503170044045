import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { PharmacyService } from '../service/pharmacy.service';
import { UserService } from '../service/user.service';
import { GlobalConstants } from '../shared/global-constants';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { User, UserRole } from '../shared/models/user.model';
import { ValidationUtil } from '../shared/ValidationUtil';
import { OkOnlyComponent } from '../shared/view/ok-only/ok-only.component';
declare var $: any;
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  INVALID_PASSWORD_ERROR = ValidationUtil.INVALID_PASSWORD_ERROR;
  INVALID_PASSWORD_ERROR_V2 = ValidationUtil.INVALID_PASSWORD_ERROR.replace(/,/g, ".<br /><b>></b>");
  constructor(private userService: UserService, private fb: FormBuilder,
    private pharmacyService: PharmacyService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }
  userList: User[] = [];
  dataSource = null;
  pharmacies: Pharmacy[] = null;
  displayedColumns: string[] = ['firstName', 'lastName', 'username', 'password', "role", "allowedPharmacy", "select"];
  ngOnInit() {

    this.pharmacyService.getAll().subscribe(x => {
      this.pharmacies = x;
      //this.pharmacies = this.pharmacies.filter(p=>p.ownerid == GlobalConstants.getcurrentUser().ownerId);
      this.setDataSource();
    });

    $('.csLoader').css({ display: 'flex' });
    this.userService.getUserAll().subscribe(x => {

      $('.csLoader').css({ display: 'none' });
      this.userList = x;
      this.setDataSource();
    }, error => {

      $('.csLoader').css({ display: 'none' });
    });

    var row = { id: "", allowedPharmacy: [], role: 1 } as User;
    this.form = this.fb.group({
      firstName: [row.firstName, [Validators.required, Validators.minLength(3)]],
      lastName: [row.lastName, [Validators.required, Validators.minLength(3)]],
      username: [row.username, [Validators.required, Validators.minLength(4)]],
      password: [row.password, [Validators.required, Validators.minLength(8)]],
      allowedPharmacy: [row.allowedPharmacy, [Validators.required]],
      ownerId: [GlobalConstants.getcurrentUser().id],
      role: [row.role, [Validators.required, Validators.minLength(3)]],
      id: [row.id],
    });
  }
  form: FormGroup;
  EditRow(row: User) {
    this.currentUser = row;
    this.extPharmacies = [];
    this.extPharmacies = [];
    this.pharmacies.forEach(element => {
      this.extPharmacies.push(element);
    });
    this.currentUser.allowedPharmacy.forEach(a => {
      var ownPharmacy = this.extPharmacies.find(x => x.id == a);
      console.log(ownPharmacy);
      if (!ownPharmacy) {
        this.extPharmacies.push({
          id: a, name: "External pharmacy", pin: "N/A"
        } as Pharmacy)
      }
    });


    this.form = this.fb.group({
      firstName: [row.firstName, [Validators.required, Validators.minLength(3)]],
      lastName: [row.lastName, [Validators.required, Validators.minLength(3)]],
      username: [row.username, [Validators.required, Validators.minLength(4)]],
      password: [row.password],
      allowedPharmacy: [row.allowedPharmacy, [Validators.required]],
      ownerId: [GlobalConstants.getcurrentUser().id],
      role: [row.role, [Validators.required, Validators.minLength(3)]],
      id: [row.id],
    });
  }

  currentUser: User = null;
  doSearch = false;
  searchForm: FormGroup;
  deliverUsers: User[] = null;
  externalUser: User = null;
  extPharmacies: Pharmacy[] = null;
  addData(isDriver: boolean) {
    if (isDriver) {
      this.deliverUsers = null;
      this.externalUser = null;
      this.searchForm = this.fb.group({
        search: ["", [Validators.required, Validators.minLength(3)]],
        yeno: [""],
        allowedPharmacy: ["", [Validators.required]]
      });
      this.doSearch = true;
    }
    else {
      this.EditRow({ id: "", allowedPharmacy: [], role: 1 } as User);
    }
  }
  onSearchSubmit() {
    this.deliverUsers = null;
    this.userService.searchPharmacyUser(this.searchForm.controls["search"].value).subscribe(x => {
      this.deliverUsers = [];
      x.forEach(element => {
        if (element.role == UserRole.DELIVERY) {
          this.deliverUsers.push(element);
        }
      });

      // if(!this.deliverUsers || this.deliverUsers.length == 0){
      //   this.deliverUsers  = this.userList;
      // }
      setTimeout(() => {
        this.searchForm.controls["yeno"].setValue("");
      }, 200);

      console.log(this.deliverUsers);
    }), (error => {
      console.log(error);
    });
  }
  yeNoChange(event) {
    this.externalUser = null;
    if (event.value == "0") {
      this.deliverUsers = null;
    }
    else if (event.value == "1") {
      this.deliverUsers = null;
      this.doSearch = false;
      this.EditRow({ id: "", role: 2, allowedPharmacy: [] } as User);
    }
    else {
      this.externalUser = this.deliverUsers.find(x => x.id == event.value);
      if (!this.externalUser.allowedPharmacy) {
        this.externalUser.allowedPharmacy = [];
      }
      this.extPharmacies = [];
      this.pharmacies.forEach(element => {
        this.extPharmacies.push(element);
      });
      console.log(this.extPharmacies);
      this.externalUser.allowedPharmacy.forEach(a => {
        var ownPharmacy = this.extPharmacies.find(x => x.id == a);
        if (!ownPharmacy) {
          this.extPharmacies.push({
            id: a, name: "External pharmacy", pin: "N/A"
          } as Pharmacy)
        }
      });
      this.searchForm.controls["allowedPharmacy"].setValue(this.externalUser.allowedPharmacy);
    }
  }
  allowDriverUser() {
    console.log(this.searchForm.controls["allowedPharmacy"].value);
    this.externalUser.allowedPharmacy = this.searchForm.controls["allowedPharmacy"].value;
    this.userService.updateUser(this.externalUser).subscribe(x => {
      var message = "Allowed pharmacies updated for " + this.externalUser.firstName + " " + this.externalUser.lastName + ""
      this.externalUser = null;
      this.deliverUsers = null;
      this.doSearch = false;
      this._snackBar.open(message, "Success", {
        duration: 3000,
      });
    });

  }
  onSubmit() {
    if (this.form.valid) {
      var newUser = this.form.value as User;
      newUser.username = newUser.username.toLowerCase();
      var passwordValidationIsRequired = newUser.password != this.currentUser.password;
      if (!passwordValidationIsRequired) {
        passwordValidationIsRequired = newUser.id == "";
      }
      
      if (passwordValidationIsRequired) {
        if (!ValidationUtil.isValidPassword(newUser.password)) {
           this.dialog.open(OkOnlyComponent, {
            width: "420px",
            data: {
              title: "Error",
              message: "<b>></b> "+ValidationUtil.INVALID_PASSWORD_ERROR.replace(/,/g,".<br/><b>></b>"),
             
            }
          });
          return;
        }
      }
    
      if (newUser.id != "") {
        var xx = this.userList.findIndex(x => x.id == newUser.id);

        this.userService.updateUser(newUser).subscribe(x => {
          console.log(x);
          if (x.data) {
            this.userList[xx] = x.data;
            this.setDataSource();
            this.currentUser = null;
          }
          else {
            this.dialog.open(OkOnlyComponent, {
              width: "420px",
              data: {
                title: "Error",
                message: x.errorMessage,

              }
            });
          }
        });
      }
      else {
        this.userService.addNewUser(newUser).subscribe(x => {
          console.log(x);
          if (x.data) {
            this.userList.push(x.data);
            this.setDataSource();
            this.currentUser = null;
          }
          else if (x.errorMessage) {
            this.dialog.open(OkOnlyComponent, {
              width: "420px",
              data: {
                title: "Error",
                message: x.errorMessage,

              }
            });
          }
        });

      }
    }
  }
  setDataSource() {
    if (!this.userList || !this.pharmacies) {
      return;
    }
    this.dataSource = new MatTableDataSource<User>(this.userList);
  }



}
