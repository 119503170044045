import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { Appointment } from '../shared/models/Appointment';
import { Questionnaire } from '../shared/models/QuestionnaireModel';
import { Service } from '../shared/models/schedual.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
 
 
 

  public PHARMACY_API = environment.baseUrl + '/schedule';
  public APPOINTMENT_API = environment.baseUrl + '/appointment';
  public WAITING_API = environment.baseUrl + '/appointment/waiting';
  public QUESTIONNAIRE_API = environment.baseUrl + '/questionnaire';


  constructor(private http: HttpClient, private datePipe: DatePipe) { }
  
  getQuestionnaire(id: any): Observable<Questionnaire[]> {
    let result: Observable<any>;
    result = this.http.get(this.QUESTIONNAIRE_API + "/" + id);
    return result;
  }
  getScheduleForPharmacy(id: string): Observable<[Service]> {
    let result: Observable<any>;
    result = this.http.get(this.PHARMACY_API + "/" + id);
    return result;
  }
  addNewService(data: any) {
    let result: Observable<any>;
    debugger;
    result = this.http.post(this.PHARMACY_API, data);
    return result;
  }

  getAppointmentForDay(pharmacyID: string, date: string, endDate: string, selectedServiceID: string): Observable<any> {
    var dt = new Date(parseInt(date.split("/")[2]), (parseInt(date.split("/")[0]) - 1), parseInt(date.split("/")[1]), 0, 0, 0, 0);
    var d = this.datePipe.transform(new Date(dt.getTime() + (dt.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");

    var dt1 = new Date(parseInt(endDate.split("/")[2]), (parseInt(endDate.split("/")[0]) - 1), parseInt(endDate.split("/")[1]), 23, 59, 59, 999);
    var d1 = this.datePipe.transform(new Date(dt1.getTime() + (dt1.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");

    // return this.http.get(this.APPOINTMENT_API + `?pharmacyID=${pharmacyID}&date=${d}&endDate=${d1}&serviceID=${selectedServiceID}`); 
    return this.http.get(this.APPOINTMENT_API + `/admin?pharmacyID=${pharmacyID}&date=${d}&endDate=${d1}`);
  }
  
  save(appointment: Appointment, cardiaRequestObject:any, isForCovid = false): Observable<any> {
    let result: Observable<any>;
    if (cardiaRequestObject != null) {
      result = this.http.post(this.APPOINTMENT_API + "/cardia", { appointment: appointment, cardiaRequestObject:cardiaRequestObject});
    }
    else {
      if (isForCovid) {
        result = this.http.post(this.APPOINTMENT_API + "/covid", appointment);
      }
      else {
        result = this.http.post(this.APPOINTMENT_API, appointment);
      }
    }
    return result;
  }
  cancelBooking(id: string, reason: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.APPOINTMENT_API + `/cancel/${id}?reason=${reason}`, null);
    return result;
  }
  updateAppointment(appointment: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.put(this.APPOINTMENT_API + `/${appointment.id}`, appointment);
    return result;
  }

  getWaitingList(selectedPharmacyID: string) {
    let result: Observable<any>;
    result = this.http.get(this.WAITING_API + "/pharmacy/"+selectedPharmacyID);
    return result;
  }
  updateWaitingStatus(id: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.put(this.WAITING_API + `/booked/${id}`, null);
    return result;
  } 
  cancelWaiting(id: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.put(this.WAITING_API + `/cancel/${id}`, null);
    return result;
  }
  generateCardiaCertificate(data: any, appointment_id:string): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.APPOINTMENT_API + `/certificate/${appointment_id}`, data);
    return result;
  }
  updateStatus(unique_id: string, appointmentId:string,status:string): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.APPOINTMENT_API + `/certificate/update/${unique_id}?appointmentId=${appointmentId}&status=${status}`, null);
    return result;
  }
  getCardiaStatus(id: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.get(this.APPOINTMENT_API + `/certificate/status/${id}`);
    return result;
  }
}
