import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
     if (this.document.location.href.indexOf("teamsdelivery.ca") > -1) {
        this.document.location.href = 'https://teamsdelivery.ca';
    }
   
  }

}
