import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-questionaire-dialog',
  templateUrl: './questionaire-dialog.component.html',
  styleUrls: ['./questionaire-dialog.component.css']
})
export class QuestionaireDialogComponent implements OnInit {

  listQuestionnaire: [any];
  questionNumber = 0;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<QuestionaireDialogComponent>) { }

  ngOnInit() {
    this.listQuestionnaire =  this.data.filter(qs => qs.isActive).sort((a, b) => { return a.sequence - b.sequence });
  }

  onConfirmClick(): void {
    var qans = [];
    this.listQuestionnaire.forEach(element => {
      qans.push({
        questionId: element.id, answer: element.answer
      });
    });
    this.dialogRef.close(qans);
  }

  shouldContinueFromQuestion = false;
  showAssessemtError = false;
  questChange(moveNext: boolean) {
    // var fails = this.questions.filter(x => x.a == "" || x.a == "Yes");
    // var yes = this.questions.filter(x => x.a == "Yes");
    this.showAssessemtError = false;
    this.shouldContinueFromQuestion = this.listQuestionnaire.filter(x => x.answer == "" && x.isMandatory).length == 0;
  
    if (this.questionNumber == this.listQuestionnaire.length - 1) {
      this.showAssessemtError = this.listQuestionnaire.filter(x => x.answer == "" && x.isMandatory).length > 0;
    }
    if (this.questionNumber < this.listQuestionnaire.length - 1 && moveNext) {
      setTimeout(() => {

        this.moveQuestion(1,false);
      }, 100);
    }
  }
  qProgress = "0%";
  moveQuestion(num: number,validate:boolean) {
    this.showAssessemtError = false;


    this.questionNumber += num;
    this.qProgress = ((this.questionNumber / (this.listQuestionnaire.length - 1)) * 100) + '%';
    if (!validate) {
      this.questChange(false);
    }
  }

}
