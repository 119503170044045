import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prescription-images',
  templateUrl: './prescription-images.component.html',
  styleUrls: ['./prescription-images.component.css']
})
export class PrescriptionImagesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PrescriptionImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  confirm(): void {
    // Call your confirm function or perform action
    this.data.confirmAction();
    this.dialogRef.close();
  }

  cancel(): void {
    // Call your cancel function or perform action
    this.data.cancelAction();
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
