import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { map, catchError } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';

import { PrescriptionService } from '../../service/prescription.service';
import { Address, Prescription } from '../../shared/models/prescription.model';
import { GlobalConstants } from 'src/app/shared/global-constants';

import { PrescriptionStatuses, PrescriptionType } from '../../shared/pipes/prescription-status.pipe'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/shared/view/confirmation-dialog/confirmation-dialog.component';
import { Pharmacy } from 'src/app/shared/models/pharmacy.model';
import { PharmacyService } from 'src/app/service/pharmacy.service';
import { UserService } from 'src/app/service/user.service';
import { CreatePatientComponent } from '../prescription-edit/create-patient/create-patient.component';

declare var $: any;
@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.css']
})
export class PrescriptionDetailComponent implements OnInit {
  sub: Subscription;
  prescription: Prescription;
  @ViewChild('patientID', null) patientID;
  patientIds = ['P001', 'P002', 'P003'];
  patientNames = ['John Doe', 'Jane Smith', 'Bob Johnson'];
  prescriptionStatusEnum = PrescriptionStatuses;
  PrescriptionTypeEnum = PrescriptionType;
  displayCarousal = false;
  noteForm: FormGroup;
  patientForm:FormGroup;
  prescriptionstatusForm: FormGroup;
  selectedValue: FormControl;
  options: string[] = [];
  selectedOption: string ;
  userSelectPharmacy: any;
  presId: string;
  prescriptionCustomStatusUpdated: any;
  searchQuery = "";
  isSearching: boolean=false;
  patientFullName: string;
  emailpatientId: any;
  emailpatientFullname: string;
  constructor(private route: ActivatedRoute, private dialog: MatDialog,
    private prescriptionService: PrescriptionService,
    private userService: UserService,
    private renderer: Renderer2,
    private _snackBar: MatSnackBar,
    private pharmacyService:PharmacyService,
    private router: Router, private fb: FormBuilder) { }

  psType = "";
  listUsers = [];
  ngOnInit() {
    this.noteForm = this.fb.group({
      note: ['', [Validators.required, Validators.minLength(3)]],
      userID: ['']
    });
    // this.patientForm = this.fb.group({
    //   patientID  : ['', [Validators.required]],
    //   searchQuery: [],
    // });
    this.patientForm = this.fb.group({
      searchQuery: [''],
      patientName: ['', Validators.required],
    });

    this.searchPharmacyPatient("");
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      localStorage.setItem("prescriptionId",params.id);
      localStorage.setItem("pstype",params.psType)
      this.psType = params.psType;
      if (id) {
        this.refreshPrescription(id);
      }
    });

    if (GlobalConstants.selectedPharmacyID) {
      // // console.log("selected pharmacy=",GlobalConstants.selectedPharmacyID);
      this.pharmacyService.getAll(false).subscribe(x => {

        this.userSelectPharmacy = x.find(x => x.id == GlobalConstants.selectedPharmacyID);
        // // console.log("Pharmacy level on detail", 'prescription_custom_status = '+this.userSelectPharmacy.prescription_custom_status);
        // console.log("options",this.userSelectPharmacy.prescription_custom_status)
       this.options = this.userSelectPharmacy.prescription_custom_status
       // console.log("options",this.options)
      });

    }

  


 
  }


  
  onSubmitPrescription() {
    // Handle form submission
    this.prescriptionCustomStatusUpdated ='';
    // console.log(this.prescriptionstatusForm.value.selectedValue);
    this.prescriptionCustomStatusUpdated=this.prescriptionstatusForm.value.selectedValue
  
    this.presId =localStorage.getItem("prescriptionId");
    const updateDataPrescription =
    {
      id: this.presId,
      prescription_custom_status:this.prescriptionCustomStatusUpdated
    }
    this.updatePrescriptionStatusByUser(updateDataPrescription)
  }

  onSubmitPatient(){

    this.emailpatientId ='';
    this.emailpatientFullname = '';
    // console.log('calling submit',this.patientForm.value.patientName.id,this.patientForm.value.patientName.firstName,);
    this.emailpatientId = this.patientForm.value.patientName.id;
    // this.prescriptionCustomStatusUpdated=this.prescriptionstatusForm.value.selectedValue
  this.emailpatientFullname = this.patientForm.value.patientName.firstName + this.patientForm.value.patientName.lastName
    this.presId =localStorage.getItem("prescriptionId");
    // console.log()
    const updateDataPatient=
    {
      id: this.presId,
      patientID:this.emailpatientId,
      patientFullName:this.emailpatientFullname
    }
      this.updatePrescriptionStatusByUser(updateDataPatient)


  }
  // isSearching = false;
  searchPharmacyPatient(query: string) {
    // console.log("caling template patient",)
    this.listUsers = [];
    this.userService.getUsersByPharmacy(GlobalConstants.selectedPharmacyID, query, 0, 100).subscribe(x => {
      this.isSearching = false;
      // console.log("x=",x)
      if (x.count > 0) {
        this.listUsers.push({ id: "", firstName: "Select patient", phoneNumber: "", show: true })
        x.data.forEach(element => {
          element.show = true;
          this.listUsers.push(element);
        });
      }
      else {
        this.listUsers.push({ id: "", firstName: "No patient found.", phoneNumber: "", show: true })
      }

    }, err => {
      this.isSearching = false;
    });
  }

  openNewPatientDialog() {
    const dialogRef = this.dialog.open(CreatePatientComponent, {
      width: '320px',
      data: { addNewOnly: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.patientID.close();
      if (result) {
        if (!this.listUsers.find(x => x.id == result.id)) {
          this.listUsers.push(result);
          this.patientID = result.id;
          this.patientID.close();
        }
        else {
          this.patientID = result.id;
          this.patientID.close();
        }
        this.patientFullName = result.firstName + " " + result.lastName;
        this.PatientChane(null);
      }
    });
  }

  oldUserSpecificNote = "";
  addresses: Array<Address> | undefined;
  PatientChane(evenet) {
    this.isSearching = false;
    // console.log("calling patient change =", evenet)
    this.prescription.deliveryAddress = {
      firstName: '',
      lastName: '',
      unitNo: '',
      buildingName: '',
      streetAddress: '',
      city: '',
      country: '',
      postalCode: ''
    };
    this.addresses = null;
    debugger;
    var user = this.listUsers.find(x => x.id == this.patientID);
    if (user) {
      this.patientFullName = user.firstName + " " + user.lastName;
      this.userService.getUserAddresses(user.id).subscribe(data => {
        this.addresses = data ? data.address : null;
        this.prescription.userSpecificNote = data ? data.note : "";
        this.oldUserSpecificNote = this.prescription.userSpecificNote
        this.prescription.isDeliveryAddressNew = this.addresses && this.addresses.length > 0 ? 0 : -1;
        setTimeout(() => {
          this.AddressChanged();
        }, 200);

      });
    }
  }
  AddressChanged() {
    debugger;
    if (this.prescription.isDeliveryAddressNew > -1) {
      this.prescription.deliveryAddress = this.addresses![this.prescription.isDeliveryAddressNew];
    }
    else {
      var selecteduser = this.listUsers.find(a => a.id == this.prescription.patientID);
      this.prescription.deliveryAddress = {
        firstName: selecteduser.firstName,
        lastName: selecteduser.lastName,
        unitNo: '',
        buildingName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: ''
      };
    }
  }


  //  isSearching = false;
  filterPatient() {
    
    if (!this.searchQuery) {
      this.isSearching = false;
    }
    if (this.searchQuery && !this.isSearching) {
      this.isSearching = true;
    }
    let wordSearch = this.searchQuery;
    setTimeout(() => {
      if (wordSearch == this.searchQuery) {
        if (this.searchQuery) {
          this.isSearching = true;
          this.searchPharmacyPatient(this.searchQuery);
        } else {
          //code here
        }
      }
    }, 1000);
  }

  updatePrescriptionStatusByUser(updateData) {
    // console.log("prescription_custom_status updated=", updateData)

    // const updateData =
    // {
    //   id: prescriptionId,
    //   prescription_custom_status:updateValue
    // }
    this.prescriptionService.updateMedicine(updateData).subscribe(resultData => {
      // console.log('result addStatusofBookedMinorailments=', resultData);
      const result = resultData;
      if (resultData) {
        // window.alert("Prescription Status updated Successfully");
        this._snackBar.open("Data updated Successfully", "Ok", {
          duration: 5000,
        });
      }
    })
  }


  order =null;
  refreshPrescription(id: string) {
    $('.csLoader').css({ display: 'flex' });
    this.prescriptionService.get(id, this.psType == "archived").subscribe((prescription: any) => {
      // console.log("calling ",prescription);
    
      if (prescription) {
        // console.log('prescription data= ',prescription);
        this.prescription = prescription;
        // // console.log('custom status',prescription.prescription_custom_status);
        this.selectedOption = prescription.prescription_custom_status;
        this.noteForm = this.fb.group({
          note: ['', [Validators.required, Validators.minLength(3)]],
          userID: GlobalConstants.getcurrentUser().id
        });
       
        this.prescriptionstatusForm = new FormGroup({
          selectedValue: new FormControl(this.selectedOption)
       });
        // // console.log(`pharmacy with id '${id}' found` + prescription,);
        this.prescription.pharmacy = (JSON.parse(localStorage.getItem("pharmacieList")) as Pharmacy[]).find(x => x.id == this.prescription.pharmacyID);
        if(this.prescription.orderId){
          this.prescriptionService.getOrder(this.prescription.orderId).subscribe(or=>{
            // console.log(or);
            this.order = or;
          });
        }
        //this.giphyService.get(car.name).subscribe(url => car.giphyUrl = url);
      } else {
        // console.log(`pharmacy with id '${id}' not found, returning to list`);

      }
      $('.csLoader').css({ display: 'none' });
    });
  }

  cancelPrescription(currentPrescriptionID: string): void {
    this.prescriptionService.cancel(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order Cancelled", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  acceptPrescription(currentPrescriptionID: string): void {
    this.prescriptionService.accept(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order Accepted", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  displayImagePopup() {
    this.displayCarousal = true;
  }

  /* deliverPrescription(currentPrescriptionID: string): void {
     this.prescriptionService.delivered(currentPrescriptionID).subscribe(result => {
       this._snackBar.open("Delivered", "OK", {
         duration: 2000,
       });
       this.refreshPrescription(this.prescription.id);
 
     }, error => {
       console.error(error);
 
     });
   }*/

  readyToPickup(currentPrescriptionID: string): void {
    this.prescriptionService.readyToPickUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order marked for ready to pick up", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }
  pickedUp(currentPrescriptionID: string): void {
    this.prescriptionService.pickedUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order marked as picked up", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  readyToDeliver(currentPrescriptionID: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        message: 'Please choose one action',
        buttonText: {
          ok: 'Track with OTP',
          cancel: 'Proceed without tracking'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed != undefined) {
        this.askForCashCollection(currentPrescriptionID, confirmed);
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });


  }
  private askForCashCollection(currentPrescriptionID: string, trackWithOtp: boolean) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        isInputRequired: true,
        placeholder:"Enter amount here if required",
        message: 'Is cash collection required?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((val: boolean) => {
      if (val != undefined) {
        var cashCollectionIsRequired = false;
        var numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/
        if (typeof val === "boolean") {
          cashCollectionIsRequired = false;
        }
        else if (numberRegex.test(val)) {
          cashCollectionIsRequired = true;
        }
        else {
          this._snackBar.open("Please enter a valid amount.", "OK", {
            duration: 2000,
          });
          return;
        }
        this.prescriptionService.readyToDeliver(currentPrescriptionID, trackWithOtp, cashCollectionIsRequired, cashCollectionIsRequired ? parseFloat(val + "") : -1).subscribe(result => {
          this._snackBar.open("Order Marked for ready to Deliver", "OK", {
            duration: 2000,
          });
          this.refreshPrescription(this.prescription.id);

        }, error => {
          console.error(error);

        });;
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });


  }

  outForDelivery(currentPrescriptionID: string): void {


    this.prescriptionService.outForDelivery(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order Marked for out for Deliver", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });


  }

  deliveryFailed(currentPrescriptionID: string): void {
    this.prescriptionService.deliveryFailed(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Delivery Failed", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  pickUpSuccess(currentPrescriptionID: string): void {
    this.prescriptionService.PickedUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Picked up succesfull", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  pickUpFailed(currentPrescriptionID: string): void {
    this.prescriptionService.pickedupFailed(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Pickup Failed", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }
  onNotSubmit() {
    if (this.noteForm.valid) {
      this.prescriptionService.addNote(this.prescription.id, this.noteForm.value).subscribe(x => {
        // console.log(x);
        if (x) {
          this.prescription = x;
          this.noteForm.controls["note"].setValue("");
          this.noteForm.controls["note"].markAsUntouched();
        }
      });
    }
  }
}
