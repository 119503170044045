import { Component, Directive, Input, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'image-preview-list',
  templateUrl: './image-preview-list.component.html',
  styleUrls: ['./image-preview-list.component.css'],
})
export class ImagePreviewListComponent implements OnInit {

  @Input() listImage: string[] = [];


  constructor() {

  }

  ngOnInit(): void {
    if (this.listImage) {
      for (var i = 0; i < this.listImage.length; i++) {
        this.listImage[i] = this.listImage[i].startsWith("http") ? this.listImage[i] : "data:image/JPEG;base64," + this.listImage[i];
      }
    }
  }
  currentImage: any;
  imageIndex = 0;
  PreviewImage(imgSrc: any, i: any) {
    $('.sticky-top').css({ "z-index": "-9" });
    this.imageIndex = i;
    this.currentImage = imgSrc ? imgSrc : this.listImage[0];
    setTimeout(() => {
      var ngx_fs_btn = $('<button  type="button" style="top: 6px; position: absolute; right: 10px; border-radius: 50%;  padding: 5px 10px; border-width: 1px;" ><span class="fa fa-arrows-alt"></span></button>');
      var parent = $("#ngx-fs-btn").parent();
      $("#ngx-fs-btn").remove();
      $(parent).append($(ngx_fs_btn));
      $(ngx_fs_btn).click((e: any) => {
        $('.showImgListForTemp').removeClass("showImgListForTemp");
        $('.sticky-top').css({ "z-index": "1020" });
        this.currentImage = null;
        e.preventDefault();
      });
    }, 300);
  }

}
