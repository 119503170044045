import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'deSanitizeOHIPNumber'
})
export class DeSanitizeOHIPNumber implements PipeTransform {
    format: string = "####-###-###-##";
    transform(value: string, formateBack: boolean = false): string {
        if(!value){
            return "";
          }
        if (formateBack) {
            value = this.doFormateBack(value);
        }
        else {
            value = value.replace(/[^0-9a-z]/gi, '');
        }
        return value.toUpperCase();
    }
    private doFormateBack(newVal: string): string {
        let value = newVal.replace(/[^0-9a-z]/gi, '');
        let valArr = value.split('');
        let fm = this.format.split('');
        for (var i = 0; i < fm.length; i++) {
            if (fm[i] == "#" && valArr.length > 0) {
                fm[i] = valArr.splice(0, 1)[0];
            }
            else if (valArr.length <= 0) {
                fm[i] = '';
            }
        }
        newVal = fm.join('');
        return newVal;
    }

}
