import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AppointmentService } from 'src/app/service/appointment.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { ConfirmationDialogComponent } from 'src/app/shared/view/confirmation-dialog/confirmation-dialog.component';
declare var $:any;
@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.css']
})
export class WaitingListComponent implements OnInit {

  constructor(private appointmentService: AppointmentService, private _snackBar: MatSnackBar, private dialog: MatDialog) { }

  waitingList = null;
  ngOnInit() {
    
    $('.csLoader').css({ display: 'flex' });
    this.appointmentService.getWaitingList(GlobalConstants.selectedPharmacyID).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.waitingList = x;
      if (x.length == 0) {
        this.waitingList = null;
      }
    },error=>{
      $('.csLoader').css({ display: 'none' });
    });
  }
  patient = null;
  serviceID = "";
  pharmacyID = "";
  currentSelectedItem = null;
  bookAppointment(item: any) {
    this.currentSelectedItem = item;
    this.patient = item.patient;
    this.pharmacyID = item.pharmacyID;
    this.serviceID = item.serviceID;
  }
  onWaitingAction(id) {
    this.serviceID = "";
    if (id) {
      this.appointmentService.updateWaitingStatus(this.currentSelectedItem.id).subscribe(x => {
        this.currentSelectedItem.status = 0;
      });
    }
  }
  cancelBooking(item: any) {

    console.log(item);
    var error = "Do you want to cancel the booking for <b/> " + item.patient.firstName + " " + item.patient.lastName + "</b>?";
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        message: error,
        buttonText: {
          ok: 'Yes',
          cancel: `No`
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed != undefined && confirmed == true) {
        this.appointmentService.cancelWaiting(item.id).subscribe(x => {
          item.status = 1;
          this._snackBar.open("Booking has been canceled.", "OK", {
            duration: 2000,
          });
        });
      }
    });

  }

}
