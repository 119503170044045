import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange, MatSnackBar } from '@angular/material';
import { PharmacyService } from 'src/app/service/pharmacy.service';
import { UserService } from 'src/app/service/user.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Pharmacy } from 'src/app/shared/models/pharmacy.model';
import { User } from 'src/app/shared/models/user.model';
declare var $: any;
@Component({
  selector: 'app-my-pharmacies',
  templateUrl: './my-pharmacies.component.html',
  styleUrls: ['./my-pharmacies.component.css']
})
export class MyPharmaciesComponent implements OnInit {


  currentUser: User = GlobalConstants.getcurrentUser();
  constructor(private pharmacyService: PharmacyService, private fb: FormBuilder, private _snackBar: MatSnackBar, private userService: UserService) { }
  form: FormGroup;
  listPharmacies: any[] = null;
  map;
  mylat = 2.265243530273438;
  mylon = 48.91753603125787;

  ngOnInit() {

    console.log(this.currentUser);
    $('.csLoader').css({ display: 'flex' });
    this.pharmacyService.getAll(true).subscribe(x => {
      this.listPharmacies = x.filter(x => x.ownerid);
      console.log(this.listPharmacies);
      this.listPharmacies.forEach(element => {
        element.isSelected = this.currentUser.allowedPharmacy.find(a => a == element.id);
      });
      $('.csLoader').css({ display: 'none' });
    }, () => {
      $('.csLoader').css({ display: 'none' });
    });
    var that = this;
    $(document).ready(function(){

      var mapOptions = {
        center: new google.maps.LatLng(that.mylat, that.mylon),
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      that.map = new google.maps.Map(document.getElementById("map"),
      mapOptions);
       var poly = new google.maps.Polyline({
      strokeColor: '#000000',
      strokeOpacity: 1,
      strokeWeight: 3,
      map: that.map,
      path:  google.maps.geometry.encoding.decodePath("ceqiHckyLoBjAu@lEU|AMt@Kl@CJH@~Bd@HBdBt@HBj@RLBdAT~@BH@F?`JTF@HD|@FPBP@J@H@hBR~@JRHFAJEDIRYRQHJHLT^PTB?`BNv@NRDfCf@HBL@NGDCfAsA|AkBJIFGDCHEH?F?@DBBBBD?HFHNJHHHnBfBFDb@OHx@J@D@jAa@RGPG|Ag@RGBAJEFAHCJC|CsAnAk@??~@c@ZOpB}@fBy@ZONVLVJTLZHVb@rAd@dBBJ|AdHv@pDJ`@d@e@FGBC^_@tAyA\\[nBgBRQPQBCRSVWBCPQfAkAp@q@@CJKHIBCdCaCNOfCaCNOPQPU^a@DIf@[RSz@s@d@a@LKO_@KHSTUCMFmAt@IDCGOe@K[_@gBASSiAUsAg@uBCMa@eBCECE@I@KKqF?K@EFMzChK??r@dCHJF`@Ld@BJJb@FPFTJVJFD?FEBK?Uk@wBw@aD}@_Eq@}BcBqFiAgEmAyEcAuDo@iCc@}A_@aBmAkFEYEWe@cC[oBKq@QmAg@gD]cCKy@O_Am@qDkAsG_@eBa@kB{@{CMa@KYKUSk@g@qAw@iBc@{A_BiEyAyDWs@M[EKKBIDGFEDGLGJSb@CBi@r@GOaCsE??OYmA}BCGCE_B`CGJEHeCnDCBABAFAHI@MBSFi@\\IF{A|@SJ]Pu@`@[PQHq@\\g@XGBMHm@^}CvAkAj@yAdA_@VIDIFe@\\IFe@\\QJ{@l@aCvAMFcC~@i@TeAd@a@PMDg@RwAj@KDGBsEfBsAh@eEbBQUc@c@_@jBQx@m@n@kAnAoArA_@`@]\\s@x@m@p@MNGDCB[Z~@pA`AtAh@h@l@f@`@f@jBfCNRlBdCpA~Aj@~@nFtIjAnAj@v@^b@b@h@`@j@rBrCZj@BDR^R\\BFo@dAo@dAu@rBe@tAsAtDsA~EUv@_AzCIPy@~AMH")
    });
    });

    // var poly = new google.maps.Polyline({
    //   strokeColor: '#000000',
    //   strokeOpacity: 1,
    //   strokeWeight: 3,
    //   map: this.map,
    //   path:  google.maps.geometry.encoding.decodePath("ceqiHckyLoBjAu@lEU|AMt@Kl@CJH@~Bd@HBdBt@HBj@RLBdAT~@BH@F?`JTF@HD|@FPBP@J@H@hBR~@JRHFAJEDIRYRQHJHLT^PTB?`BNv@NRDfCf@HBL@NGDCfAsA|AkBJIFGDCHEH?F?@DBBBBD?HFHNJHHHnBfBFDb@OHx@J@D@jAa@RGPG|Ag@RGBAJEFAHCJC|CsAnAk@??~@c@ZOpB}@fBy@ZONVLVJTLZHVb@rAd@dBBJ|AdHv@pDJ`@d@e@FGBC^_@tAyA\\[nBgBRQPQBCRSVWBCPQfAkAp@q@@CJKHIBCdCaCNOfCaCNOPQPU^a@DIf@[RSz@s@d@a@LKO_@KHSTUCMFmAt@IDCGOe@K[_@gBASSiAUsAg@uBCMa@eBCECE@I@KKqF?K@EFMzChK??r@dCHJF`@Ld@BJJb@FPFTJVJFD?FEBK?Uk@wBw@aD}@_Eq@}BcBqFiAgEmAyEcAuDo@iCc@}A_@aBmAkFEYEWe@cC[oBKq@QmAg@gD]cCKy@O_Am@qDkAsG_@eBa@kB{@{CMa@KYKUSk@g@qAw@iBc@{A_BiEyAyDWs@M[EKKBIDGFEDGLGJSb@CBi@r@GOaCsE??OYmA}BCGCE_B`CGJEHeCnDCBABAFAHI@MBSFi@\\IF{A|@SJ]Pu@`@[PQHq@\\g@XGBMHm@^}CvAkAj@yAdA_@VIDIFe@\\IFe@\\QJ{@l@aCvAMFcC~@i@TeAd@a@PMDg@RwAj@KDGBsEfBsAh@eEbBQUc@c@_@jBQx@m@n@kAnAoArA_@`@]\\s@x@m@p@MNGDCB[Z~@pA`AtAh@h@l@f@`@f@jBfCNRlBdCpA~Aj@~@nFtIjAnAj@v@^b@b@h@`@j@rBrCZj@BDR^R\\BFo@dAo@dAu@rBe@tAsAtDsA~EUv@_AzCIPy@~AMH")
    // });
  } 
  change(event: MatCheckboxChange, id) {

  }
  SaveData() {
    var selected = this.listPharmacies.filter(x => x.isSelected).map(x => x.id);
    console.log(selected);
    this.currentUser.allowedPharmacy = selected;
    this.currentUser.password = null;
    this.userService.updateUser(this.currentUser).subscribe(x => {
      if (x.errorMessage) {
        this._snackBar.open(x.errorMessage, "Error", {
          duration: 4000,
        });
      }
      else {
        localStorage.setItem(GlobalConstants.CURRENT_USER, JSON.stringify(this.currentUser));
        this.userService.logInSuccess.next(this.currentUser);
        this._snackBar.open("Data saved successfully.", "Success", {
          duration: 4000,
        });
      }
    }, (err) => {
      this._snackBar.open("Some thing went wrong.", "Error", {
        duration: 4000,
      });
    })
  }

}
