export interface Product {
    id: number | null;
    productName: string;
    productPrice: string;
    productImg?: string;
    // vendor:string;
    productOrder?: any;
}



 export const products: Product[] = [
    { 
        id: 1,
       productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture40.png?v=1667928749',
       productName: 'Blood Sugar Support (VegCap)',
       // productPrice: '$39.99',
       productPrice: '$24.00',
       // vendor: '',
       productOrder:'https://greeniche.wize.health/products/Blood-Sugar-support-vegcap'
   },

   {
    id: 2,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture1.png?v=1667921418',
    productName: 'Calcium (500mg) & Vitamin D (1,000IU) (Tablets)',
    productPrice: '$7.75',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Calcium-500mg-Vitamin-D-1-000IU-Tablets'
},


{
    id: 3,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture2_74a97b96-90f3-471c-b8dc-b5df9d9c5aef_350x350.png?v=1669310987',
    productName: 'CoQ10 100mg (VegCap)',
    productPrice: '$13.50 ',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/collections/greeniche/products/coq10-100mg-vegcap'
},

{
    id: 4,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture44.png?v=1667929526',
    productName: 'Curcumin Gold (VegCap)',
    // productPrice: '$29.99',
    productPrice: '$18.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Curcumin-Gold-vegcap'
},
    
{  id: 5,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture34.png?v=1667927808',
    productName: 'Eye Health Support (VegCap)',
    // productPrice: '$39.99',
    productPrice: '$24.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Eye-Health-support-vegcap'
},

{
    id: 6,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture32.png?v=1667927587',
    productName: 'Hair, Skin & Nails Formula (VegCap)',
    // productPrice: '$29.99',
    productPrice: '$18.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Hair-Skin-Nails-Formula-vegcap'
},


{
    id: 7,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture48.png?v=1667930226',
    productName: 'Immunity Support (VegCap)',
    // productPrice: '$24.99',
    // vendor: '',
    productPrice: '$15.00',
    productOrder:'https://greeniche.wize.health/products/Immunity-support-vegcap'
},


{
    id: 8,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture24.png?v=1667926031',
    productName: 'Joints Health Formula (VegCap)',
    // productPrice:'$22.99',
    productPrice: '$13.75',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Joints-Health-Formula-vegcap'
},


{
    id: 9,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture7.png?v=1667922382',
    productName: 'Kids Multivitamin (Chewable Tablets)',
    // productPrice: '$15.99',
    productPrice: '$8.90',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Kids-Multivitamin-Chewable-Tablets'
},

{
    id: 10,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture42.png?v=1667928877',
    productName: 'Kidney Health Support (VegCap)',
    // productPrice: '$29.99',
    productPrice: '$18.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Kidney-Health-support-vegcap'
},

{
    id: 11,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture3_37540520-d52b-43ce-be3f-38292810bbab_350x350.png?v=1669311300',
    productName: 'Liver Health Formula (VegCap)',
    productPrice: '$18.00',
    productOrder:'https://greeniche.wize.health/collections/greeniche/products/liver-health-formula-vegcap'
},
{
    id: 12,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture20.png?v=1667924217',
    productName: 'Magnesium Chelate 100mg (VegCap)',
    // productPrice: '$14.99',
    productPrice: '$9.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Magnesium-Chelate-100mg-vegcap'
},

{
    id: 13,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture13.png?v=1667923007',
    productName: 'Men over 50 Multivitamin (Tablets)',
    // productPrice:'$13.99',
    productPrice: '$8.50',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Men-over-50-Multivitamin-Tablets'
},

{
    id: 14,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture11.png?v=1667922763',
    productName: " Men's Multivitamin (Tablets)",
    // productPrice:'$13.99',
    productPrice: '$8.50',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Mens-Multivitamin-Tablets'
},

{
    id: 15,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture46.png?v=1667930719',
    productName: 'Moringa Oliefera (VegCap)',
    // productPrice: '$24.99',
    productPrice: '$15.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Moringa-Oliefera-vegcap'
},

{
    id: 16,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture38.png?v=1667928600',
    productName: 'Nopause (VegCap)',
    // productPrice: '$29.99',
    productPrice: '$18.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Nopause-vegcap'
},

{
    id: 17,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture31.png?v=1667927420',
    productName: 'Omega 3 (Softgel)',
    // productPrice: '$24.99',
    productPrice: '$15.00',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Omega-3-Softgel'
},

{
    id: 18,
    productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture22.png?v=1667924421',
    productName: 'Prenatal Multivitamin (Tablets)',
    // productPrice:'$18.99',
    productPrice: '$10.60',
    // vendor: '',
    productOrder:'https://greeniche.wize.health/products/Prenatal-Multivitamin-Tablets'
},
{
            id: 19,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture50.png?v=1667930371',
            productName: 'Probiotic Support (VegCap)',
            // productPrice:'$29.99',
            productPrice:'$18.00',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/probiotic-support-vegcap'
        },
      
      
      
       
       
        {
            id: 20,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture36.png?v=1667928466',
            productName: 'Prostate Health Support (VegCap)',
            // productPrice: '$29.99',
            productPrice: '$18.00',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/Prostate-Health-support-vegcap'
        },
      

      
     
        {
            id: 21,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture28.png?v=1667926655',
            productName: 'VegeSlim (VegCap)',
            // productPrice: '$39.99',
            productPrice: '$24.00',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/VegeSlim-vegcap'
        },

        
        {
            id: 22,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture9.png?v=1667922565',
            productName: 'Veg-Iron 100mg (VegCap)',
            // productPrice:'$10.99',
            productPrice:'$6.25',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/Veg-Iron-100mg-vegcap'
        },

        {
            id: 23,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture1_38232bf7-36b9-48e1-91ef-7a9f8a1206bf_350x350.png?v=1669310821',
            productName: 'Vitamin B-12 1,200mcg (Tablets)',
            productPrice: '$8.50',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/collections/greeniche/products/vitamin-b-12-1200mcg-tablets'
        },

        {  id: 24,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture18.png?v=1667923624',
            productName: 'Vitamin C 500mg (Timed Release Tablets)',
            productPrice: '$12.00',
            productOrder:'https://greeniche.wize.health/products/Vitamin-c-500mg-Timed-Release-Tablets'
        },
        {
            id: 25,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture26.png?v=1667926470',
            productName: 'VigorMan (VegCap)',
            // productPrice: '$34.99',
            productPrice: '$21.00',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/VigorMan-vegcap'
        },
       
        {
            id: 26,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture3.png?v=1667921726',
            productName: 'Vitamin D 1,000IU (Tablets)',
            productPrice: '$5.00',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/Vitamin-D-1-000IU-Tablets'
        },        
     

        
        {
            id: 27,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture5.png?v=1667922135',
            productName: 'Vitamin D 400IU (Drops)',
            productPrice: '$6.75',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/Vitamin-D-400IU-Drops'
        },

        {
            id: 28,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture15.png?v=1667923289',
            productName: 'Women’s Multivitamin (Tablets)',
            // productPrice:'$13.99',
            productPrice: '$8.50',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/Women-s-Multivitamin-Tablets'
        },
        {
            id: 29,
            productImg: 'https://cdn.shopify.com/s/files/1/0517/5446/7491/products/Picture17.png?v=1667923405',
            productName: 'Women Over 50 Multivitamin (Tablets)',
            // productPrice: '$15.99',
            productPrice: '$9.25',
            // vendor: '',
            productOrder:'https://greeniche.wize.health/products/Women-Over-50-Multivitamin-Tablets'
        },

     
       
      
     
      
       
    ];


  