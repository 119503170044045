export interface TimeSlot {
    timeSlotName: string;
    startTime: string;
    endTime: string;
    gmtEndTime:Date|any;
    gmtStartTime:Date|any;
}

export interface DaysOfTheWeek {
    active: boolean;
    day: number;
    timeSlots: TimeSlot[];
}

export interface Service {
    id: string;
    pharmacyID: string;
    startDate: any;
    endDate: any;
    daysOfTheWeek: DaysOfTheWeek[];
    slotDuration: number;
    isActive: boolean;
    obsolete: boolean;
    serviceName: string;
    active: boolean;
    conditions: Conditions[];
    serviceType: number;
    serviceProvider:number;
}

export interface Conditions {
    propertyName: string;
    propertyValueMustBe: string;
    errorMessage: string;
    isActive: boolean;
    condition: ConditionTypes;
}
export enum ConditionTypes {
    EQUALS_TO,
    GREATER_THAN,
    GREATER_THAN_EQUAL,
    LESS_THAN,
    LESS_THAN_EQUAL,
}