import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ailments-dialog-component',
  templateUrl: './ailments-dialog-component.component.html',
  styleUrls: ['./ailments-dialog-component.component.css']
})
export class AilmentsDialogComponentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AilmentsDialogComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  
 

  ngOnInit() {
    console.log("Anjali")
  }
  onCancel(): void {
    this.dialogRef.close();
  }
}
