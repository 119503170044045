import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-ok-only',
  templateUrl: './ok-only.component.html',
  styleUrls: ['./ok-only.component.css']
})
export class OkOnlyComponent implements OnInit {
  message = "Do you want to perform this action";
  title = "Confirmation";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OkOnlyComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.title = data.title || this.title;
     
    }
  }

  ngOnInit() {
  }
  onConfirmClick(val: boolean): void {
    this.dialogRef.close(val);
  }

}
